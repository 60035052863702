<template>
  <b-container>
    <h5 class="mt-4">{{ $t("label.terminatedUserForm") }}</h5>

    <b-form @submit="onSubmit" v-if="buildings.length > 0">
      <!-- Fin integration formulaire RH -->
      <b-row class="pt-2" v-if="multiTenantSelect">
        <b-col sm="6" class="mt-2">
          <div>
            <strong>Microsoft Tenant</strong>
          </div>
          <b-form-select v-model="tenant" :options="tenantList" v-on:change="OnTenantClick"
            class="mt-2"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="pt-2" v-else>
        <b-col sm="6" class="mt-2">
          <div>
            <strong>Microsoft Tenant : </strong>
            {{ tenant }}
          </div>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-alert show variant="info">{{ $t('info.accessTermination') }}</b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="mt-2">
          <v-select v-model="existingUser" :options="users" @search="searchUser" :placeholder="searchByMail === 'yes'
            ? $t('action.chooseUserByMail')
            : $t('action.chooseUser')
            " :label="searchByMail === 'yes' ? 'mail' : 'displayName'">
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.searchByName") }}</small>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="mt-1">
          <b-form-checkbox class="mt-1" v-model="searchByMail" name="searchByMail" value="yes" unchecked-value="no">{{
            $t("label.searchByMail") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <!-- Integration formulaire RH -->
      <b-row>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.fname") }}</strong>
          </div>
          <b-form-input v-model.trim="$v.form.fname.$model" :placeholder="$t('label.fname')"></b-form-input>
          <div class="text-danger" v-if="!$v.form.fname.required && $v.form.fname.$dirty">
            {{ $t("info.required") }}
          </div>
          <div class="text-danger" v-if="!$v.form.fname.maxLength && $v.form.fname.$dirty">
            {{ $t("info.maxLength", [75]) }}
          </div>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.lname") }}</strong>
          </div>
          <b-form-input v-model.trim="$v.form.lname.$model" :placeholder="$t('label.lname')"></b-form-input>
          <div class="text-danger" v-if="!$v.form.lname.required && $v.form.lname.$dirty">
            {{ $t("info.required") }}
          </div>
          <div class="text-danger" v-if="!$v.form.lname.maxLength && $v.form.lname.$dirty">
            {{ $t("info.maxLength", [75]) }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="mt-2">
          <div>
            <strong>{{ $t("label.offices") }}</strong>
          </div>
          <v-select v-model.trim="$v.form.office.$model" :options="buildings" :selectable="(option) => !option.Flag"
            :filter="searchBuilding" label="ResName">
            <template slot="option" slot-scope="option">
              <strong style="color: black" v-if="option.Flag === 'ClassName'" disabled>{{ option.ResName }}</strong>
              <span v-else>{{ option.ResName }}</span>
            </template>
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.searchBuilding") }}</small>
          </div>
          <div class="text-danger" v-if="!$v.form.office.required && $v.form.office.$dirty">
            {{ $t("info.required") }}
          </div>
        </b-col>
        <b-col sm="6" class="mt-2">
          <div>
            <strong>{{ $t("label.terminationReason") }}</strong>
          </div>
          <b-form-select v-model.trim="$v.form.terminationReason.$model"
            :options="terminationReasonList"></b-form-select>
          <div class="text-danger" v-if="
            !$v.form.terminationReason.required && $v.form.terminationReason.$dirty
          ">
            {{ $t("info.required") }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6" class="mt-2">
          <div>
            <strong>{{ $t("label.lastDayOfWorked") }}</strong>
          </div>
          <datepicker input-class="datepicker" v-model="form.lastDayOfWorked" :placeholder="$t('action.chooseDate')"
            :language="language"></datepicker>
          <div class="text-danger" v-if="!$v.form.lastDayOfWorked.required &&
            $v.form.lastDayOfWorked.$dirty
          ">
            {{ $t("info.required") }}
          </div>
        </b-col>
      </b-row>
      <!-- textarea -->
      <b-row class="mt-2">
        <b-col sm="6">
          <div>
            <strong>{{ $t("label.comments") }}</strong>
          </div>
          <b-form-textarea id="rhcomments" v-model="$v.form.rhcomments.$model" :placeholder="$t('info.comments')"
            rows="5" max-rows="10"></b-form-textarea>
          <div class="text-danger" v-if="!$v.form.rhcomments.maxLength && $v.form.rhcomments.$dirty">
            {{ $t("info.maxLength", [2000]) }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="6">
          <div>
            <strong>{{ $t("label.attachSupportingDocument") }}</strong>
            <div><small>&#9432; {{ $t("label.infoSupportingDoc") }}</small></div>
          </div>
          <b-form-file v-model.trim="uploadDocuments" name="uploadDocuments" :lang="this.$i18n.locale"
            ref="uploadDocuments" :placeholder="$t('action.choosefile')" @change="validateMultipleFile"
            :file-name-formatter="formatNames" multiple accept="image/*,.pdf, .doc,.docx,.xml,
            application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"></b-form-file>
          <div>
            <small>&#9432; {{ $t("label.infoUploadSupportingDoc") }}</small>
          </div>
          <div class="text-danger" v-if="errorUploadDocuments">
            {{ errorUploadDocuments }}
          </div>
          <b-button @click="clearFiles('uploadDocuments')" size="sm" class="mt-3"
            :disabled="disabledClear('uploadDocuments')">{{ $t("action.clearField") }}</b-button>
        </b-col>
      </b-row>
      <hr />
      <div v-if="existingUser">
        <b-row>
          <b-col sm="6" class="mt-4">
            <b-row>
              <b-col>
                <b-alert show variant="info">
                  <div>
                    <strong>{{ $t("label.displayName") }}</strong> :
                    {{ existingUser.displayName }}
                  </div>
                  <div>
                    <strong>{{ $t("label.job") }}</strong> :
                    {{ existingUser.jobTitle }}
                  </div>
                  <div>
                    <strong>{{ $t("label.email") }}</strong> :
                    {{ existingUser.mail }}
                  </div>
                  <div>
                    <strong>{{ $t("label.manager") }}</strong> :
                    {{
                      existingUser.manager
                        ? existingUser.manager.displayName
                        : ""
                    }}
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col sm>
            <div class="mb-2">
              <strong>{{ $t("label.typeOfTermination") }}</strong>
            </div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="form.sendingType" :aria-describedby="ariaDescribedby" name="sendingType"
                value="immediate">{{ $t("label.rightnowTermination") }}
                <span v-b-tooltip.hover :title="$t('tooltip.rightnowTermination')">&#9432;</span></b-form-radio>
              <b-form-radio v-model="form.sendingType" :aria-describedby="ariaDescribedby" name="sendingType"
                value="schedule">{{ $t("label.scheduleTermination") }}
                <span v-b-tooltip.hover :title="$t('tooltip.scheduleTermination')">&#9432;</span></b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="ml-4" v-if="form.sendingType === 'schedule'">
          <b-col sm>
            <div>
              <strong>{{ $t("label.departureDate") }}</strong>
            </div>
            <datepicker input-class="datepicker" v-model="form.departureDate" :placeholder="$t('action.chooseDate')"
              :language="language" :disabledDates="disableddepartureDate"></datepicker>
            <div class="text-danger" v-if="!$v.form.departureDate.required &&
              $v.form.departureDate.$dirty
            ">
              {{ $t("info.required") }}
            </div>
            <div>
              <vue-timepicker class="mt-2" :minute-interval="5" v-model="form.departureTime"
                hide-clear-button></vue-timepicker>
            </div>
            <b-button variant="link" @click="reset()">{{
              $t("action.reset")
            }}</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" class="mt-2">
            <b-form-checkbox id="keepHistory" v-model="form.keepHistory" name="keepHistory" value="yes"
              unchecked-value="no">{{
                $t("label.keepHistory") }}
              <strong>{{
                form.keepHistoryEmail ? form.keepHistoryEmail.mail : ""
                }}</strong>
              <span v-b-tooltip.hover :title="$t('tooltip.keepHistory')">&#9432;</span>
            </b-form-checkbox>
            <!-- keepHistoryEmail -->
            <div class="ml-4 mt-2" v-if="form.keepHistory === 'yes'">
              <v-select v-model.trim="$v.form.keepHistoryEmail.$model" :options="users" @search="searchUser"
                :placeholder="$t('action.chooseUser')" label="displayName">
              </v-select>
              <small>&#9432; {{ $t("info.searchByName") }}</small>
              <div class="text-danger" v-if="!$v.form.keepHistoryEmail.required &&
                $v.form.keepHistoryEmail.$dirty
              ">
                {{ $t("info.required") }}
              </div>
            </div>
            <b-form-checkbox id="forwardEmail" v-model="form.forwardEmail" name="forwardEmail" value="yes"
              unchecked-value="no">{{ $t("label.forwardEmail") }}
              <strong>{{
                form.sendAccessTo ? form.sendAccessTo.mail : ""
                }}</strong>
              <span v-b-tooltip.hover :title="$t('tooltip.forwardEmail')">&#9432;</span>
            </b-form-checkbox>

            <!-- Send Access To -->
            <div class="ml-4 mt-2" v-if="form.forwardEmail === 'yes'">
              <v-select v-model.trim="$v.form.sendAccessTo.$model" :options="users" @search="searchUser"
                :placeholder="$t('action.chooseUser')" label="displayName" class="mt-2">
              </v-select>
              <small>&#9432; {{ $t("info.searchByName") }}</small>
              <b-form-checkbox id="keepInMailbox" v-model="form.keepInMailbox" name="keepInMailbox" value="yes"
                unchecked-value="no" class="mt-2">{{ $t("label.keepInMailbox") }}
              </b-form-checkbox>

              <div class="text-danger" v-if="!$v.form.sendAccessTo.required && $v.form.sendAccessTo.$dirty
              ">
                {{ $t("info.required") }}
              </div>
            </div>
            <b-form-checkbox v-if="form.forwardEmail === 'no'" id="accessRestriction" v-model="form.accessRestriction"
              name="accessRestriction" value="yes" unchecked-value="no">{{ $t("label.accessRestriction") }}
              <span v-b-tooltip.hover :title="$t('tooltip.accessRestriction')">&#9432;</span>
            </b-form-checkbox>
            <b-form-checkbox id="autoreply" v-model="form.autoreply" name="autoreply" value="yes"
              unchecked-value="no">{{
                $t("label.autoreply") }}
            </b-form-checkbox>
            <div class="ml-4 mt-2" v-if="form.autoreply === 'yes'">
              <!-- Editor for message -->
              <vue-editor class="mt-4" v-model.trim="form.autoreplyMessage" :editorToolbar="customToolbar"></vue-editor>
              <div class="text-danger" v-if="!$v.form.autoreplyMessage.required &&
                $v.form.autoreplyMessage.$dirty &&
                !$v.form.autoreplyMessage.requiredAfterHtmlStripped
              ">
                {{ $t("info.required") }}
              </div>
              <div class="text-danger" v-if="!$v.form.autoreplyMessage.maxLength && $v.message.$dirty">
                {{ $t("info.maxLength", [5000]) }}
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- textarea -->
        <b-row class="mt-2">
          <b-col sm="6">
            <div>
              <strong>{{ $t("label.comments") }}</strong>
            </div>
            <b-form-textarea id="comments" v-model="$v.form.comments.$model" :placeholder="$t('info.comments')" rows="5"
              max-rows="10"></b-form-textarea>
            <div class="text-danger" v-if="!$v.form.comments.maxLength && $v.form.comments.$dirty">
              {{ $t("info.maxLength", [2000]) }}
            </div>
          </b-col>
        </b-row>
        <!-- Access request list -->
        <div class="mt-2">
          <div>
            <strong>{{ $t("label.accessRequestToRemove") }}</strong>
          </div>

          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="softwareAccess" v-model="accessRequestList" :aria-describedby="ariaDescribedby"
              name="remote" stacked>
              <b-form-checkbox v-if="form.office && form.office.Class === 10" value="vpn">VPN</b-form-checkbox>
              <b-form-checkbox v-if="form.office &&
                form.office.Class === 10 &&
                form.office.Id === 304
              " value="accesscardmtl">Carte d'accès Montréal</b-form-checkbox>
              <b-form-checkbox value="remote">{{ $t("label.remoteAccess") }}
                <span v-b-tooltip.hover :title="$t('tooltip.portailRemoteApp')">&#9432;</span></b-form-checkbox>
              <b-form-checkbox value="agile">Agile</b-form-checkbox>

              <b-form-checkbox v-for="cs in collaboratorService" :key="cs.id" :value="cs.Service">{{ currentLg === "en"
                ?
                cs.En : cs.Fr }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>

      <b-button class="mt-4" type="submit" variant="success" :disabled="disabled">{{
        $t("action.submit") }}</b-button>
    </b-form>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner style="width: 3rem; height: 3rem" variant="success" label="Spinning"
        disabled="labelStatus"></b-spinner>
    </div>

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
        <b-alert show variant="success">
          <p>{{ $t("info.successgeneric") }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="error" class="mt-2">
      <b-col sm class="mt-4">
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="info" class="mt-2">
      <b-col sm class="mt-4">
        <b-alert show variant="info">
          <p>{{ info }}</p>
        </b-alert>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { VueEditor } from "vue2-editor";
import { required, maxLength } from "vuelidate/lib/validators";
import "vue-search-select/dist/VueSearchSelect.css";
import { globalF } from "../Helpers";

const requiredAfterHtmlStripped = (message) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = message;
  let content = tmp.textContent || tmp.innerText || "";
  return content.length > 0 ? true : false;
};

export default {
  mixins: [globalF],
  data() {
    return {
      form: {
        autoreply: "no",
        autoreplyMessage: "",
        keepHistory: "no",
        keepHistoryEmail: "",
        forwardEmail: "no",
        keepInMailbox: "no",
        removedManager: "yes",
        removedDl: "yes",
        accessRestriction: "no",
        office: null,
        department: "",
        salaryView: "no",
        sendAccessTo: "",
        otherSoftware: "",
        rhcomments: "",
        comments: "",
        sendingType: "immediate",
        departureDate: "",
        departureTime: "00:00",
        fullname: "",
        fname: "",
        lname: "",
        divisionName: null,
        payrollCompany: null,
        terminationReason: null,
        lastDayOfWorked: "",
        uploadResultInfo: [],
        uploadError: ""
      },
      tenant: this.getCurrentTenant(),
      searchByMail: "no",
      searchManagerByMail: "no",
      allDomains: [],
      buildings: [],
      existingUser: null,
      spinner: false,
      success: false,
      disabled: false,
      users: [],
      error: "",
      info: "",
      accessRequestList: [],
      filesTotalSize: 0,
      sizeLimit: 10485760,
      uploadDocuments: null,
      errorUploadDocuments: ""
    };
  },
  validations() {
    let form = {
      sendAccessTo: {},
      keepHistoryEmail: {},
      fname: {
        required,
        maxLength: maxLength(75),
      },
      lname: {
        required,
        maxLength: maxLength(75),
      },
      office: { required },
      terminationReason: { required },
      lastDayOfWorked: { required },
      autoreplyMessage: {},
      comments: { maxLength: maxLength(2000) },
      rhcomments: { maxLength: maxLength(2000) }
    };

    if (this.form.forwardEmail === "yes") {
      form.sendAccessTo = { required };
    }

    if (this.form.keepHistory === "yes") {
      form.keepHistoryEmail = { required };
    }

    if (this.form.autoreply === "yes") {
      form.autoreplyMessage = {
        required,
        requiredAfterHtmlStripped,
        maxLength: maxLength(5000),
      };
    }

    if (this.form.sendingType === "schedule") {
      form.departureDate = { required };
    }

    return { form };
  },
  mounted() {
    if (this.tenant) {
      this.spinner = true;
      this.getAppTokenForTenant();
    }
  },
  computed: {
    multiTenantSelect() {
      let currentAccount = this.$store.getters.getMicrosoftAccount;
      let tenantAccess = this.$store.getters.getTenantAppAccess;
      let multiTenantSelectList = tenantAccess.multiTenantSelect;
      return multiTenantSelectList.includes(currentAccount.tenantId);
    },
    tenantList: function () {
      let tenantList = this.$store.getters.getTenantList;
      let tenantAccess = this.$store.getters.getTenantAppAccess;
      let terminationFormList = tenantAccess.terminationForm;

      let filterList = tenantList.filter(t => terminationFormList.includes(t.id));

      return filterList.map(t => {
        return {
          "value": t.value,
          "text": t.name
        }
      });
    },
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    customToolbar: function () {
      //:editorToolbar="customToolbar"
      return [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ color: [] }],
        ["link"],
      ];
    },
    collaboratorService: function () {
      let cs = this.$store.getters.getCollaboratorService;
      let filterCs = cs.filter(
        (c) =>
          c.Service !== "vpn" &&
          c.Service !== "remote" &&
          c.Service !== "agile" &&
          c.Service !== "accesscardmtl"
      );
      filterCs.sort((a, b) => {
        if (a.Service < b.Service) {
          return -1;
        }
        if (a.Service > b.Service) {
          return 1;
        }
        return 0;
      });
      return filterCs;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    disableddepartureDate: function () {
      let myDate = moment().startOf("day").toDate();
      return { to: myDate };
    },
    terminationReasonList: function () {
      let terminationReasonList = this.$store.getters.getRHTerminationReason;
      return terminationReasonList.map(t => {
        return {
          "value": t,
          "text": this.$i18n.locale === "fr" ? t.Fr : t.En
        }
      });
    }
  },
  watch: {
    existingUser: function (user) {
      if (user) {
        this.form.office = this.getOfficeDataByName(user.officeLocation);
        this.form.fname = user.givenName;
        this.form.lname = user.surname;
        this.actionCall("userMemberOf");
        this.disabled = false;
        this.success = false;
        this.error = "";
        this.info = "";
      }
    },
  },
  methods: {
    OnTenantClick() {
      if (this.tenant) {
        this.spinner = true;
        this.buildings = [];
        this.token = "";
        this.error = "";
        this.info = "";
        this.users = [];
        this.existingUser = "";
        this.form.keepHistoryEmail = "";
        this.form.sendAccessTo = "";
        this.getAppTokenForTenant();
      }
    },
    getAppTokenForTenant() {
      if (this.tenant) {
        let tenantList = this.$store.getters.getTenantList;
        let tenantId = tenantList.find(t => t.value === this.tenant).id;
        let obj = {
          request: "getAppTokenByTenant",
          tenantId: tenantId,
        };
        axios
          .post(this.url, obj, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            let accessTokenObj = JSON.parse(response.data.accessToken);
            this.token = accessTokenObj.access_token;
            this.getAllDomains(accessTokenObj.access_token);
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getAllDomains: function (token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/domains`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.spinner = false;
          this.allDomains = response.data.value;
          this.getBuildingList();
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getCurrentTenant() {
      let tenantList = this.$store.getters.getTenantList;
      let currentAccount = this.$store.getters.getMicrosoftAccount;
      let defaultTenant = this.$store.getters.getDefaultTenant;
      let tenantAccess = this.$store.getters.getTenantAppAccess;
      let terminationForm = tenantAccess.terminationForm;

      if (terminationForm.includes(currentAccount.tenantId)) {
        return tenantList.find(t => t.id === currentAccount.tenantId).value;
      }
      else {
        return tenantList.find(t => t.id === defaultTenant).value;
      }
    },
    showInput: function (value) {
      return this.accessRequestList.includes(value);
    },
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return (
          City.toLowerCase().includes(s) ||
          ClassNamesFr.toLowerCase().includes(s) ||
          ClassNamesEn.toLowerCase().includes(s) ||
          ResName.toLowerCase().includes(s) ||
          Country.toLowerCase().includes(s) ||
          State.toLowerCase().includes(s) ||
          Street.toLowerCase().includes(s) ||
          Zip.toLowerCase().includes(s)
        );
      });

      return find;
    },
    getOfficeDataByName: function (resName) {
      let buildings = this.$store.getters.getBuildings;
      let building = buildings.find((b) => b.ResName === resName);
      return building ? building : null;
    },
    getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapBuilding(buildings);
    },
    mapBuilding: function (buildings) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < buildings.length; i++) {
        let find = uniqueClass.find((c) => c === buildings[i].Class);
        if (!find) {
          uniqueClass.push(buildings[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let buildingsListByClassWithLabel = [];

      for (let i = 0; i < uniqueClass.length; i++) {
        let blist = buildings.filter((b) => b.Class === uniqueClass[i]);
        let className =
          lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
        blist.unshift({ ResName: className, Flag: "ClassName" });
        buildingsListByClassWithLabel.push(blist);
      }

      this.buildings = buildingsListByClassWithLabel.flat();
    },
    searchUser: function (search) {
      this.actionCall("allUsers", search);
    },
    actionCall: function (type, searchText) {
      this.info = "";
      this.error = "";
      this.success = false;

      if (type === "allUsers") {
        this.searchUsersBySearchText(this.token, searchText);
      }
      if (type === "userMemberOf") {
        this.getUserMemberOf(this.token);
      }

    },
    getAllUsers(token, searchText) {
      if (searchText) {
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchText}')&$expand=manager($select=id,displayName,mail)&$count=true&$top=10`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.spinner = false;
            if (response.status === 200) {
              this.users = response.data.value.filter(
                (a) =>
                  a.displayName.indexOf("*") === -1 &&
                  this.validateTenantDomain(a.mail, this.allDomains)
              );
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getUserMemberOf(token) {
      this.spinner = true;
      let idUser = this.existingUser.id;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/memberOf`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.spinner = false;
          this.getAccessFormByUser(idUser);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getAccessFormByUser(idUser) {
      let obj = {
        request: "getAccessFormByUser",
        idUser: idUser,
      };
      axios
        .post(this.url, obj, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          this.setAccessRequest(response.data);
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    setAccessRequest(accessFormList) {
      let accessRequestList = [];
      if (accessFormList.length > 0) {
        for (let i = 0; i < accessFormList.length; i++) {
          let accessRequestStr = accessFormList[i].AccessRequest;
          if (this.isValidJson(accessRequestStr)) {
            let accessRequest = JSON.parse(accessRequestStr);
            if (Array.isArray(accessRequest)) {
              for (let j = 0; j < accessRequest.length; j++) {
                accessRequestList.push(accessRequest[j]);
              }
            }
          }
        }
      }
      this.accessRequestList = [...new Set(accessRequestList)];
    },
    reset: function () {
      this.form.departureDate = "";
      this.form.departureTime = "00:00";
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    validateDate() {
      let pass = true;
      if (this.form.sendingType === "schedule") {
        let departureDate = moment(this.form.departureDate).format(
          "YYYY-MM-DD"
        );
        let departureTime = this.form.departureTime;
        let departureDateTime = moment(
          `${departureDate} ${departureTime}`,
          "YYYY-MM-DD HH:mm"
        );
        let today = moment();
        if (departureDateTime < today) {
          this.error = this.$t("info.dateAfterToday");
          pass = false;
        }
      }
      return pass;
    },
    //Upload file section
    disabledClear: function (form) {
      if (form === "uploadDocuments") {
        return this.uploadDocuments ? false : true;
      }
    },
    clearFiles(ref) {
      if (ref === "uploadDocuments") {
        this.$refs["uploadDocuments"].reset();
        this.uploadDocuments = null;
        this.errorUploadDocuments = "";
      }
    },
    validateMultipleFile(event) {
      let myfiles = event.target.files;
      let filename = event.target.name;
      console.log(myfiles);

      if (filename === "uploadDocuments") {
        this.errorUploadDocuments = "";
      }

      this.validateFilesLength(myfiles, filename);
      this.validateFilesType(myfiles, filename);
    },
    validateFilesLength: function (myfiles, filename) {
      if (myfiles.length > 3) {
        if (filename === "uploadDocuments") {
          this.errorUploadDocuments = this.$t("info.limitNumberOfFiles");
        }
      }
    },
    validateFilesType: function (myfiles, filename) {
      let typeList = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
        ".doc", ".docx", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ];
      for (let i = 0; i < myfiles.length; i++) {
        let found = typeList.includes(myfiles[i].type);
        if (!found || myfiles[i].size > this.sizeLimit) {
          if (filename === "uploadDocuments") {
            this.errorUploadDocuments = this.$t("info.invalidFileType");
          }
        }
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      } else {
        return `${files.length} files selected`;
      }
    },
    getFilesToUpload(ticketApiObj) {
      if (this.errorUploadDocuments === "" && this.uploadDocuments.length > 0) {
        for (let i = 0; i < this.uploadDocuments.length; i++) {
          let uploadFile = this.uploadDocuments[i];

          const filereader = new FileReader();

          filereader.onload = (event) => {
            this.uploadFile(ticketApiObj, event.target.result, uploadFile);
          };

          filereader.readAsArrayBuffer(uploadFile);
        }
      }
    },
    uploadFile(ticketApiObj, file, uploadFile) {
      let token = ticketApiObj.key;
      let userEmail = ticketApiObj.user;
      let baseUrl = ticketApiObj.url;
      let filename = uploadFile.name;
      let fileType = uploadFile.type;

      let endpoint = `${baseUrl}api/v2/uploads?filename=${filename}`;
      let encodeAuth = btoa(`${userEmail}/token:${token}`);
      axios
        .post(endpoint, file, {
          headers: {
            "Content-Type": fileType,
            "Authorization": 'Basic ' + encodeAuth
          },
          transformRequest: (data, headers) => {
            delete headers.common['myprofile-api-key'];
            delete headers.common['tenant-id'];

            return data;
          }
        })
        .then((response) => {
          this.form.uploadResultInfo.push(response.data);
          if (this.form.uploadResultInfo.length == this.uploadDocuments.length) {
            this.terminateRhemployee()
          }
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
          this.form.uploadError = error;
          this.form.requestType = "uploadFileInitiateTermination";
          this.logError();
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.success = false;
      this.error = "";
      this.info = "";
      this.$v.$touch();
      if (!this.$v.$invalid && this.validateDate()) {
        this.disabled = true;
        this.spinner = true;
        this.validateUser();
      }
    },
    validateUser() {
      if (this.existingUser) {

        this.form.request = "validateUser";
        this.form.user = this.existingUser;

        axios
          .post(this.url, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.data === 0) {
              if (this.uploadDocuments) {
                this.uploadTicketInfo();
              }
              else {
                this.terminateRhemployee();
              }
            } else if (response.data === "userAlreadyExistInTerminationTable") {
              this.disabled = false;
              this.spinner = false;
              this.info = this.$t("info.userAlreadyExistInTerminationTable");
            } else {
              this.disabled = false;
              this.spinner = false;
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.disabled = false;
            this.spinner = false;
          });
      }
      else {
        if (this.uploadDocuments) {
          this.uploadTicketInfo();
        }
        else {
          this.terminateRhemployee();
        }
      }
    },
    uploadTicketInfo() {
      axios
        .post(this.url, { request: "uploadTicketInfo" }, {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          this.getFilesToUpload(response.data);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    terminateRhemployee() {
      let payrollCompany = this.form.office.payrollCompanyName ? this.form.office.payrollCompanyName : "";
      let payrollCompanyNumber = this.form.office.payrollCompanyNumber ? this.form.office.payrollCompanyNumber : "";
      let payrollCompanyName = `${payrollCompany} - ${payrollCompanyNumber}`;
      
      this.form.request = "terminateRhemployee";
      this.form.resName = this.form.office.ResName;
      this.form.idBuilding = this.form.office.Id;
      this.form.payrollCompany = payrollCompanyName;
      this.form.divisionName = this.form.office.ClassNamesFr;
      this.form.class = this.form.office.Class;
      this.form.terminationReason = this.form.terminationReason.Fr;
      this.form.requesterId = this.user.id;
      this.form.requesterName = this.user.displayName;
      this.form.requesterEmail = this.user.mail;
      this.form.lastDayOfWorked = moment(this.form.lastDayOfWorked).format(
        "YYYY-MM-DD"
      );

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.data === 0) {
            this.terminateGraphUser();
          } else if (response.data === "userAlreadyExistInTerminationTable") {
            this.disabled = false;
            this.info = this.$t("info.userAlreadyExistInTerminationTable");
          } else {
            this.disabled = false;
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    terminateGraphUser() {
      if (this.existingUser) {
        if (this.form.forwardEmail === "yes") {
          this.form.accessRestriction = "no";
        }
        this.form.requesterId = this.user.id;
        this.form.requesterName = this.user.displayName;
        this.form.requesterEmail = this.user.mail;
        this.form.user = this.existingUser;
        this.form.idBuilding = this.form.office ? this.form.office.Id : 0;
        this.form.class = this.form.office ? this.form.office.Class : 0;

        this.form.sendAccessTo = this.form.sendAccessTo
          ? this.form.sendAccessTo.mail
          : "";
        this.form.keepHistoryEmail = this.form.keepHistoryEmail
          ? this.form.keepHistoryEmail.mail
          : "";

        this.form.request = "terminateGraphUser";
        this.form.accessRequestList = this.accessRequestList;

        if (this.form.sendingType === "schedule") {
          this.form.departureDate = moment(this.form.departureDate).format(
            "YYYY-MM-DD"
          );
        } else {
          this.form.departureDate = "";
          this.form.departureTime = "";
        }

        let tenantList = this.$store.getters.getTenantList;

        this.form.tenantName = tenantList.find(t => t.value === this.tenant).name;
        this.form.tenantId = tenantList.find(t => t.value === this.tenant).id;

        axios
          .post(this.url, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            this.spinner = false;
            if (response.data === 0) {
              this.$emit("changePage", "SuccessConfirmation");
            } else if (response.data === "userAlreadyExistInTerminationTable") {
              this.disabled = false;
              this.info = this.$t("info.userAlreadyExistInTerminationTable");
            } else {
              this.disabled = false;
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.disabled = false;
            this.spinner = false;
          });
      }
      else {
        this.$emit("changePage", "SuccessConfirmation");
      }
    },
    logError: function () {
      this.disabled = true;
      this.spinner = true;

      this.form.request = "accessFormLog";

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.disabled = false;
          this.spinner = false;

        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    }
  },
  components: {
    VueEditor,
    Datepicker,
    VueTimepicker,
  },
};
</script>