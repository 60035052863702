<template>
  <b-container>
    <div>
      <h5 class="mt-4">
        {{ $t("label.createUser") }}
        <b-button variant="link" @click="openmodal('ticketInfo')">{{
          $t("action.viewTicketInfo")
        }}</b-button>
        <b-button variant="link" @click="goTo('Admin', 'TicketTable')">{{
          $t("label.backToCurrentOpenTicket")
        }}</b-button>
      </h5>

      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <div>
            <strong>Microsoft Tenant</strong>
          </div>
          <b-form-select v-model="tenant" :options="tenantList" v-on:change="OnTenantClick" class="mt-2"
            disabled></b-form-select>
        </b-col>
      </b-row>

      <b-form @submit="onSubmit" v-if="buildings.length > 0">
        <b-row>
          <b-col sm class="mt-4">
            <div>
              <strong>{{ $t("label.fname") }}</strong>
            </div>
            <b-form-input v-model.trim="$v.form.fname.$model" :placeholder="$t('label.fname')"></b-form-input>
            <div class="text-danger" v-if="!$v.form.fname.required && $v.form.fname.$dirty">
              {{ $t("info.required") }}
            </div>
            <div class="text-danger" v-if="!$v.form.fname.maxLength && $v.form.fname.$dirty">
              {{ $t("info.maxLength", [75]) }}
            </div>
          </b-col>
          <b-col sm class="mt-4">
            <div>
              <strong>{{ $t("label.lname") }}</strong>
            </div>
            <b-form-input v-model.trim="$v.form.lname.$model" :placeholder="$t('label.lname')"></b-form-input>
            <div class="text-danger" v-if="!$v.form.lname.required && $v.form.lname.$dirty">
              {{ $t("info.required") }}
            </div>
            <div class="text-danger" v-if="!$v.form.lname.maxLength && $v.form.lname.$dirty">
              {{ $t("info.maxLength", [75]) }}
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6" class="mt-4">
            <div>
              <strong>{{ $t("label.offices") }}</strong>
            </div>
            <v-select v-model.trim="$v.form.office.$model" :options="buildings" :selectable="(option) => !option.Flag"
              :filter="searchBuilding" label="ResName" :placeholder="$t('action.chooseBuilding')" :value="form.office"
              @input="setJobTitleList">
              <template slot="option" slot-scope="option">
                <strong style="color: black" v-if="option.Flag === 'ClassName'" disabled>{{ option.ResName }}</strong>
                <span v-else>{{ option.ResName }}</span>
              </template>
            </v-select>
            <div>
              <small>&#9432; {{ $t("info.searchBuilding") }}</small>
            </div>
            <div class="text-danger" v-if="!$v.form.office.required && $v.form.office.$dirty">
              {{ $t("info.required") }}
            </div>
            <b-button variant="link" @click="openmodal('sendNotificationEmail', null)">{{
              $t("label.cannotFindMyBuilding")
              }}</b-button>
          </b-col>
          <b-col sm="6" class="mt-4">
            <div>
              <strong>{{ $t("label.job") }}</strong>
            </div>
            <v-select v-model.trim="$v.form.job.$model" :options="jobTitleList" :selectable="(option) => !option.Flag"
              :filter="searchJobTitleList" label="jobTitle" :placeholder="$t('label.job')">
              <template slot="option" slot-scope="option">
                <strong style="color: black" v-if="option.Flag === 'ClassName'" disabled>{{ option.jobTitle
                  }}</strong>
                <span v-else>{{ option.jobTitle }}</span>
              </template>
            </v-select>
            <div>
              <small>&#9432; {{ $t("info.jobTitleList") }}</small>
            </div>
            <div class="text-danger" v-if="!$v.form.job.required && $v.form.job.$dirty">
              {{ $t("info.required") }}
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6" class="mt-4">
            <div>
              <strong>{{ $t("label.manager") }}</strong> :
              {{ displayManagerName() }}
            </div>
            <v-select v-model.trim="$v.form.manager.$model" :options="users" @search="searchUser"
              :placeholder="$t('action.chooseManager')" label="displayName">
            </v-select>
            <div class="text-danger" v-if="!$v.form.manager.required && $v.form.manager.$dirty">
              {{ $t("info.required") }}
            </div>
          </b-col>
          <b-col sm="6">
            <div class="mt-4">
              <strong>{{ $t("label.communication") }}</strong>
            </div>
            <b-form-select v-model.trim="$v.form.communication.$model" :options="communication"></b-form-select>
            <div class="text-danger" v-if="!$v.form.communication.required && $v.form.communication.$dirty
            ">
              {{ $t("info.required") }}
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-row class="mt-2">
          <b-col sm>
            <div>
              <strong>{{ $t("label.campaignStartDate") }}</strong>
            </div>
            <datepicker input-class="datepicker" v-model="form.startDate" :placeholder="$t('action.chooseDate')"
              :language="language"></datepicker>
            <div class="text-danger" v-if="!$v.form.startDate.required && $v.form.startDate.$dirty">
              {{ $t("info.required") }}
            </div>
          </b-col>
        </b-row>

        <hr />

        <b-row class="mt-2">
          <b-col sm="4">
            <div>
              <strong>{{ $t("label.email") }}</strong>
            </div>
            <b-input-group :append="'@' + form.domain">
              <b-form-input v-model="form.nameForEmail" class="text-right"></b-form-input>
            </b-input-group>
            <div class="text-danger" v-if="!$v.form.nameForEmail.required && $v.form.startDate.$dirty">
              {{ $t("info.required") }}
            </div>
          </b-col>
          <b-col sm="4">
            <div>
              <strong>{{ $t("label.domains") }}</strong>
            </div>
            <b-form-select v-model="form.domain" :options="domains"></b-form-select>
            <div class="text-danger" v-if="!$v.form.domain.required && $v.form.startDate.$dirty">
              {{ $t("info.required") }}
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col sm="4" class="mt-4">
            <div>
              <strong>{{ $t("label.password") }}</strong>
            </div>
            <b-form-input v-model.trim="$v.form.password.$model" :placeholder="$t('label.password')"></b-form-input>
            <div class="text-danger" v-if="!$v.form.password.required && $v.form.password.$dirty">
              {{ $t("info.required") }}
            </div>
            <div class="text-danger" v-if="!$v.form.password.maxLength && $v.form.password.$dirty">
              {{ $t("info.maxLength", [75]) }}
            </div>
            <div class="text-danger" v-if="!$v.form.password.minLength && $v.form.password.$dirty">
              {{ $t("info.minLength", [8]) }}
            </div>
          </b-col>
          <b-col sm="4" class="mt-4">
            <div class="mt-4">
              <b-button variant="outline-info" @click="setNewPassword()">{{
                $t("action.generatePassword")
              }}</b-button>
            </div>
          </b-col>
        </b-row>

        <hr />

        <b-row class="mt-2">
          <b-col>
            <div class="mt-2">
              <b-alert show variant="info">
                <div>
                  <strong>{{ $t("info.dlForAllAgent") }}</strong>
                </div>

                <div class="mt-2" v-if="corpoDlList.length > 0">
                  <ul>
                    <li v-for="item in corpoDlList" :key="item.id">
                      {{ item.displayName }}
                    </li>
                  </ul>
                </div>

                <div class="mt-2" v-if="additionalDl.length > 0">
                  <ul>
                    <li v-for="item in additionalDl" :key="item.id">
                      {{ item.displayName }}
                    </li>
                  </ul>
                  <b-button variant="link" @click="resetMultiSelectList('distributionList')">{{
                    $t("action.resetTheList")
                  }}</b-button>
                </div>

                <div class="mt-4" v-if="distListFromGraph.length > 0">
                  <b-button variant="outline-info" @click="openmodal('multiSelectTable')">{{ $t("info.addToDlAgent")
                    }}</b-button>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

        <hr />

        <b-row class="mt-2">
          <b-col>
            <div class="mt-2">
              <b-alert show variant="info">
                <div>
                  <strong>{{ $t("info.licensesForAgent") }}</strong>
                </div>

                <div class="mt-2" v-if="licensesListForUser.length > 0">
                  <ul>
                    <li v-for="item in licensesListForUser" :key="item.id">
                      {{ item.displayName }}
                    </li>
                  </ul>
                  <b-button variant="link" @click="resetMultiSelectList('licenseList')">{{ $t("action.resetTheList")
                    }}</b-button>
                </div>

                <div class="mt-4" v-if="licensesList.length > 0">
                  <b-button variant="outline-info" @click="openmodal('multiSelectTableLicense')">{{
                    $t("info.addLicensesForUser") }}</b-button>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

        <b-button v-if="distListFromGraph.length > 0 && licensesList.length > 0" class="mt-4" type="submit"
          variant="success" :disabled="disabled">{{ $t("action.submit") }}</b-button>

        <b-modal id="ticketInfo" :title="$t('label.ticketInfo')" hide-footer>
          <ul>
            <li v-for="(value, key) in currentUser" :key="key">
              {{ key + " : " + value }}
            </li>
          </ul>
        </b-modal>

        <b-modal id="sendNotificationEmail" :title="$t('label.emailNotification')" hide-footer>
          <SendNotificationEmail modalid="sendNotificationEmail" />
        </b-modal>

        <b-modal id="multiSelectTable" size="xl" :title="$t('label.distributionList')" hide-footer>
          <MultiSelectTable ref="multiSelectDl" :tableDataFromProp="distListFromGraph"
            :fields="fieldsForMultipleSelectTable" tableName="distributionList" :corpoDlList="corpoDlList" />
        </b-modal>

        <b-modal id="multiSelectTableLicense" size="xl" :title="$t('label.licenses')" hide-footer>
          <MultiSelectTable ref="multiSelectLicenses" :tableDataFromProp="licensesList"
            :fields="fieldsForMultipleSelectTableLicenses" tableName="licenseList" :corpoDlList="corpoDlList" />
        </b-modal>
      </b-form>
      <div v-if="spinner" class="text-center align-middle">
        <b-spinner style="width: 3rem; height: 3rem" variant="success" label="Spinning"
          disabled="labelStatus"></b-spinner>
      </div>

      <b-row v-if="success" class="text-center pt-4">
        <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="error" class="mt-2">
        <b-col sm class="mt-4">
          <b-alert show variant="danger">
            <p>{{ error }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import "vue-search-select/dist/VueSearchSelect.css";
import SendNotificationEmail from "../Modal/SendNotificationEmail";
import MultiSelectTable from "./MultipleSelectTable.vue";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      tenant: this.getCurrentTenant(),
      token: null,
      accessFormLogObj: {
        createUser: null,
        assignLicense: null,
        updateManager: null,
        newUser: null,
      },
      accessRequestList: [],
      distListFromGraph: [],
      rawData: [],
      licensesList: [],
      form: {
        id: null,
        fname: this.getData("Fname"),
        lname: this.getData("Lname"),
        email: null,
        job: this.getJobTitle(),
        office: this.getOfficeData(),
        manager: "",
        communication: this.getData("PreferedLanguage"),
        startDate: this.getStartDate(),
        nameForEmail: this.getData("NameForEmail"),
        domain: this.getData("Domain"),
        agileRequest: false,
        department: "",
        salaryView: "no",
        sendAccessTo: "",
        otherSoftware: "",
        comments: "",
        requestType: "setAccessFormRequest",
        password: this.generatePassword(),
      },
      requestType: "new",
      managers: [],
      spinner: false,
      success: false,
      disabled: false,
      users: [],
      existingUser: null,
      buildings: [],
      jobTitleList: [],
      allDomains: [],
      domains: [],
      error: "",
      info: "",
    };
  },
  validations() {
    let form = {
      fname: {
        required,
        maxLength: maxLength(75),
      },
      lname: {
        required,
        maxLength: maxLength(75),
      },
      job: {
        required
      },
      manager: { required },
      communication: { required },
      office: { required },
      startDate: { required },
      nameForEmail: { required },
      domain: { required },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(75),
      },
    };

    return { form };
  },
  mounted() {
    this.spinner = true;
    this.error = "";
    this.getAppTokenForTenant();
  },
  computed: {
    tenantList: function () {
      let tenantList = this.$store.getters.getTenantList;
      return tenantList.map(t => {
        return {
          "value": t.value,
          "text": t.name
        }
      });
    },
    corpoDlList: function () {
      let corpoDlList = [];

      let building = this.getBuildingByName();
      let office = building ? building : this.form.office;

      if (office) {
        let officeClass = office.Class;
        let officeId = office.Id;
        //à mettre en bd
        if (officeClass === 10 && officeId === 302) {
          corpoDlList = this.getCorpoList("dist_Cogir-Brossard");
        }
        if (officeClass === 10 && officeId === 303) {
          corpoDlList = this.getCorpoList("dist_Cogir-Quebec");
        }
        if (officeClass === 10 && officeId === 304) {
          corpoDlList = this.getCorpoList("dist_Cogir-Montreal");
        }
        if (officeClass === 10 && officeId === 305) {
          corpoDlList = this.getCorpoList("dist_Cogir-Whitby");
        }
      }

      return corpoDlList;
    },
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    currentUser: function () {
      return this.$store.getters.getCurrentUserToCreate;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    communication: function () {
      return [
        { value: null, text: this.$t("label.language") },
        { value: "fr-FR", text: this.$t("label.french") },
        { value: "en-US", text: this.$t("label.english") },
      ];
    },
    licensesListForUser: function () {
      return this.$store.getters.getLicenseList;
    },
    additionalDl: function () {
      return this.$store.getters.getAdditionnalDlList;
    },
    fieldsForMultipleSelectTable: function () {
      return [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        { key: "mail", sortable: true, label: this.$t("label.email") },
        {
          key: "createdDateTime",
          sortable: true,
          label: this.$t("label.creation"),
        },
        { key: "Details", label: this.$t("label.details") },
      ];
    },
    fieldsForMultipleSelectTableLicenses: function () {
      return [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        { key: "skuPartNumber", sortable: true, label: "Sku Part Number" },
        {
          key: "prepaidUnits",
          sortable: true,
          label: this.$t("label.enabled"),
          formatter: (value, key, item) =>
            this.getPrepaidUnitsValue(value, key, item),
        },
        { key: "Details", label: this.$t("label.details") },
      ];
    },
  },
  watch: {
    currentLg: function () {
      this.actionCall("building");
    },
  },
  methods: {
    setJobTitleList: function (value) {
      this.form.job = null;
      if (value) {
        this.getJobTitleList(value.Class);
      }
      else {
        this.jobTitleList = [];
      }
    },
    getAppTokenForTenant() {
      if (this.tenant) {
        let tenantList = this.$store.getters.getTenantList;
        let tenantId = tenantList.find(t => t.value === this.tenant).id;
        let obj = {
          request: "getAppTokenByTenant",
          tenantId: tenantId,
        };
        axios
          .post(this.url, obj, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            let accessTokenObj = JSON.parse(response.data.accessToken);
            this.token = accessTokenObj.access_token;
            this.getDataForCreateUser(accessTokenObj.access_token);
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getDataForCreateUser(token) {
      this.$store.commit("addLicenseList", []);
      //this.$store.getters.getAppAccessToken;
      let user = this.$store.getters.getCurrentUserToCreate;
      this.getManager(user.ManagerId, token);
    },
    OnTenantClick() {
      if (this.tenant) {
        this.spinner = true;
        this.error = "";
        this.getAppTokenForTenant();
      }
    },
    getCurrentTenant() {
      //let currentAccount = this.$store.getters.getMicrosoftAccount;
      let user = this.$store.getters.getCurrentUserToCreate;
      let tenantList = this.$store.getters.getTenantList;
      let currentTenant = tenantList.find(t => t.value === "Cogir").value;
      if (user.TenantId) {
        currentTenant = tenantList.find(t => t.id === user.TenantId).value;
      }
      return currentTenant;
    },
    setNewPassword: function () {
      this.form.password = this.generatePassword();
    },
    getCorpoList: function (corpoName) {
      return this.distListFromGraph.filter(
        (dl) => dl.displayName === corpoName
      );
    },
    setAdditionalDl: function (dlFromGraph) {
      let dlFromBd = [];

      if (dlFromGraph) {
        let dlIdFromBd = this.getData("DistributionList");
        if (dlIdFromBd) {
          if (dlIdFromBd.length > 0 && dlFromGraph.length > 0) {
            dlFromBd = dlFromGraph.filter((dl) => {
              if (dlIdFromBd.includes(dl.id)) {
                return dl;
              }
            });
          }
        }
      }
      this.$store.commit("addAdditionnalDlList", dlFromBd);
    },
    goTo: function (val, table) {
      this.$store.commit("setAdminActiveTab", table);
      this.$emit("changePage", val);
    },
    getPrepaidUnitsValue: function (value, key, item) {
      let enabled = parseInt(value.enabled);
      let assigned = parseInt(item.consumedUnits);
      let available = "";

      if (Number.isNaN(enabled) || Number.isNaN(assigned)) {
        available = "";
      } else {
        available = enabled - assigned;
      }

      return available;
    },
    resetMultiSelectList: function (listName) {
      if (listName === "distributionList") {
        this.$store.commit("addAdditionnalDlList", []);
      }
      if (listName === "licenseList") {
        this.$store.commit("addLicenseList", []);
      }
    },
    getStartDate: function () {
      let startDate = this.getData("StartDate");
      return startDate ? moment(startDate).toDate() : "";
    },
    getData: function (type) {
      let user = this.$store.getters.getCurrentUserToCreate;
      let propertyValue = user[type];
      return propertyValue ? propertyValue : "";
    },
    showInput: function (value) {
      return this.accessRequestList.includes(value);
    },
    getJobTitle: function () {
      let lg = this.$i18n.locale;
      let jobTitle = this.getData("Job");
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleMap = jobTitleList.map(l => {
        l.jobTitle = lg === "fr" ? l.Fr : l.En;
        return l;
      })
      let jobTitleObj = jobTitleMap.find((b) => b.Fr === jobTitle);
      if (!jobTitleObj) {
        jobTitleObj = jobTitleMap.find((b) => b.En === jobTitle);
      }

      return jobTitleObj ? jobTitleObj : null;
    },
    getOfficeData: function () {
      let idBuilding = this.getData("IdBuilding");
      let buildings = this.$store.getters.getBuildings;
      let building = buildings.find((b) => b.Id === idBuilding);
      return building ? building : null;
    },
    getBuildingByName: function () {
      let buildings = this.$store.getters.getBuildings;
      let building = buildings.find((b) => b.ResName === this.form.office);
      return building ? building : null;
    },
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return (
          City.toLowerCase().includes(s) ||
          ClassNamesFr.toLowerCase().includes(s) ||
          ClassNamesEn.toLowerCase().includes(s) ||
          ResName.toLowerCase().includes(s) ||
          Country.toLowerCase().includes(s) ||
          State.toLowerCase().includes(s) ||
          Street.toLowerCase().includes(s) ||
          Zip.toLowerCase().includes(s)
        );
      });

      return find;
    },
    searchUser: function (searchText) {
      this.actionCall("allUsers", searchText);
    },
    searchJobTitleList(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let JobTitlePar = o.Fr ? o.Fr.replace(/[()]/g, "") : ""; //Remove ()
        let JobTitle = JobTitlePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        return (
          JobTitle.toLowerCase().includes(s)
        );
      });

      return find;
    },
    actionCall: function (type, searchText) {
      this.info = "";
      this.error = "";
      this.success = false;

      if (type === "allUsers") {
        this.searchUsersBySearchText(this.token, searchText);
      }
      if (type === "building") {
        this.getBuildingList();
      }

    },
    getAllUsers(token, searchText) {
      if (searchText) {
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchText}')&$expand=manager($select=id,displayName)&$count=true&$top=10`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.spinner = false;
            if (response.status === 200) {
              this.users = response.data.value.filter(
                (a) =>
                  a.displayName.indexOf("*") === -1 &&
                  this.validateTenantDomain(a.mail, this.allDomains)
              );
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getGroups: function (token, graphEndpoint) {
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getGroups(token, nextLink);
            } else {
              let flatList = this.rawData.flat();
              this.distListFromGraph = flatList;
              this.setAdditionalDl(flatList);
              this.getBuildingList();
              this.spinner = false;
            }
          } else {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getDomains(token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/domains`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let allDomains = response.data.value;
          this.allDomains = response.data.value;
          if (allDomains.length > 0) {
            this.domains = allDomains.map((d) => {
              return {
                value: d.id,
                text: d.id,
              };
            });
          }
          this.getLicenses(token);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getLicenses(token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/subscribedSkus`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let productNameList = this.$store.getters.getGraphProductName;
          this.licensesList = this.mapLicencesWithProductName(
            response.data.value,
            productNameList
          );
          let graphEndpoint = "https://graph.microsoft.com/v1.0/groups";
          this.getGroups(token, graphEndpoint);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    mapLicencesWithProductName(licenses, productsName) {
      return licenses.map((l) => {
        let pnobject = productsName.find(
          (p) => p.String_Id === l.skuPartNumber
        );
        l.displayName = l.skuPartNumber;
        if (pnobject) {
          l.displayName = pnobject.Product_Display_Name;
        }
        return l;
      });
    },
    getManager(idManager, token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idManager}`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          //this.spinner = false;
          this.form.manager = response.data;
          this.getDomains(token);
        })
        .catch(() => {
          this.getDomains(token);
        });
    },
    getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapSelectList(buildings, "building");
    },
    getJobTitleList: function (classId) {
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleFilter = jobTitleList.filter(j => j.Class === classId);
      this.mapSelectList(jobTitleFilter, "jobTitle");
    },
    mapSelectList: function (list, type) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < list.length; i++) {
        let find = uniqueClass.find((c) => c === list[i].Class);
        if (!find) {
          uniqueClass.push(list[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let ListByClassWithLabel = [];

      if (type === 'building') {
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = list.filter((b) => b.Class === uniqueClass[i]);
          let className =
            lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          blist.unshift({ ResName: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }
        this.buildings = ListByClassWithLabel.flat();
      }

      if (type === 'jobTitle') {
        let jobTitleMap = list.map(l => {
          l.jobTitle = lg === "fr" ? l.Fr : l.En;
          return l;
        })
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = jobTitleMap.filter((b) => b.Class === uniqueClass[i]);
          let className = lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          blist.unshift({ jobTitle: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }

        this.jobTitleList = ListByClassWithLabel.flat();
      }
    },
    /*getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapBuilding(buildings);
    },
    mapBuilding: function (buildings) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < buildings.length; i++) {
        let find = uniqueClass.find((c) => c === buildings[i].Class);
        if (!find) {
          uniqueClass.push(buildings[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let buildingsListByClassWithLabel = [];

      for (let i = 0; i < uniqueClass.length; i++) {
        let blist = buildings.filter((b) => b.Class === uniqueClass[i]);
        let className =
          lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
        blist.unshift({ ResName: className, Flag: "ClassName" });
        buildingsListByClassWithLabel.push(blist);
      }

      this.buildings = buildingsListByClassWithLabel.flat();
    },*/
    displayManagerName: function () {
      let manager = this.form.manager;
      let name = "";
      if (manager) {
        name = manager.displayName ? manager.displayName : "";
      }
      return name;
    },
    openmodal: function (id) {
      this.$bvModal.show(id);
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    generatePassword() {
      let characters = "a-z,A-Z,0-9";
      let passwordSize = 5;

      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      /*if (charactersArray.indexOf("#") >= 0) {
        //CharacterSet += "![]{}()%&*$#^<>~@|";
        CharacterSet += "!%&*$#@";
      }*/
      for (let i = 0; i < passwordSize; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }

      password += "6xV";

      return password.charAt(0).toUpperCase() + password.slice(1);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.error = "";
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.disabled = true;
        this.spinner = true;

        this.validateJobStatus();
      }
    },
    //Validation before sending
    validateJobStatus() {
      let obj = {
        request: "validateJobStatus",
        idRef: this.currentUser.Id,
        jobName: "AddGroupMember",
      };
      axios
        .post(this.url, obj, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log("validateJobStatus");
          if (response.data) {
            this.spinner = false;
            this.disabled = false;
            this.error = this.$t("info.jobExistForThisId");
          } else {
            this.createUser();
          }
        })
        .catch(() => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
        });
    },
    createUser() {
      let displayName = this.form.fname + " " + this.form.lname;
      let mail = this.form.nameForEmail + "@" + this.form.domain;

      let building = this.getBuildingByName();

      let newUser = {
        accountEnabled: true,
        displayName: displayName,
        mailNickname: this.form.nameForEmail,
        userPrincipalName: mail,
        mail: mail,
        passwordProfile: {
          forceChangePasswordNextSignIn: false,
          password: this.form.password,
        },
        employeeHireDate: moment(this.form.startDate).format(),
        surname: this.form.lname,
        givenName: this.form.fname,
        jobTitle: this.form.job ? this.form.job.jobTitle : "",
        officeLocation: building ? building.ResName : this.form.office.ResName,
        country: building ? building.Country : this.form.office.Country,
        usageLocation: building
          ? building.CountryCode
          : this.form.office.CountryCode,
        state: building ? building.State : this.form.office.State,
        city: building ? building.City : this.form.office.City,
        streetAddress: building ? building.Street : this.form.office.Street,
        postalCode: building ? building.Zip : this.form.office.Zip,
        preferredLanguage: this.form.communication,
      };

      this.accessFormLogObj.newUser = newUser;

      //let token = this.$store.getters.getAppAccessToken;
      let token = this.token;
      let graphEndpoint = "https://graph.microsoft.com/v1.0/users";

      axios
        .post(graphEndpoint, newUser, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.accessFormLogObj.createUser = response.data;

            let userId = response.data.id;
            this.updateManager(token, userId);
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.error = this.$t("info.errorCreateUser");
          if (error.response) {
            this.error = error.response.data;
          }
          this.accessFormLogObj.createUser = error;
          this.logCreateUserError("");
        });
    },
    getLicensesListObj() {
      let licenses = this.licensesListForUser.map((l) => {
        return {
          skuId: l.skuId,
        };
      });

      return {
        addLicenses: licenses,
        removeLicenses: [],
      };
    },
    updateManager(token, userId) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${userId}/manager/$ref`;
      axios
        .put(
          graphEndpoint,
          {
            "@odata.id": `https://graph.microsoft.com/v1.0/users/${this.form.manager.id}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 204) {
            this.accessFormLogObj.updateManager = response.data;
            console.log("updateManager success");

            if (this.licensesListForUser.length > 0) {
              let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${userId}/assignLicense`;
              let licensesListObj = this.getLicensesListObj();
              this.assignLicense(token, licensesListObj, graphEndpoint, userId);
            } else {
              this.logCreateUserRequest(userId);
            }
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          this.accessFormLogObj.updateManager = error;
          this.logCreateUserError(userId);
        });
    },
    assignLicense(token, obj, graphEndpoint, userId) {
      axios
        .post(graphEndpoint, obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("assignLicense success");
            this.accessFormLogObj.assignLicense = response.data;
            this.logCreateUserRequest(userId);
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          this.accessFormLogObj.assignLicense = error;
          this.logCreateUserError(userId);
        });
    },
    logCreateUserRequest: function (userId) {

      this.form.request = "dispatchCreateUserRequest";

      let tenantList = this.$store.getters.getTenantList;
      this.form.tenantId = tenantList.find(t => t.value === this.tenant).id;
      this.form.newGraphUserId = userId;

      //DL et Microsoft 365 Group
      this.form.groupList = this.additionalDl.concat(this.corpoDlList);
      let distList = this.form.groupList.filter(
        (s) => s.groupTypes.length === 0
      );
      let microsoftList = this.form.groupList.filter((s) =>
        s.groupTypes.includes("Unified")
      );
      this.form.distributionList = distList.map((s) => {
        return s.id;
      });
      this.form.microsoft365Group = microsoftList.map((s) => {
        return s.id;
      });

      this.form.accessFormLogObj = this.accessFormLogObj;
      this.form.currentUserInfo = this.currentUser;
      this.form.startDate = moment(this.form.startDate).format("YYYY-MM-DD");
      this.form.idBuilding = this.form.office.Id;
      this.form.building = this.form.office;
      this.form.managerId = this.form.manager.id;
      this.form.managerName = this.form.manager.displayName;
      this.form.managerEmail = this.form.manager.mail;
      this.form.licenses = this.getLicensesListObj();
      this.form.job = this.form.job ? this.form.job.jobTitle : "";

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.spinner = false;
          this.$emit("changePage", "SuccessConfirmation");
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    logCreateUserError: function (userId) {
      this.disabled = true;
      this.spinner = true;

      this.form.request = "accessFormLog";
      this.form.newGraphUserId = userId;
      this.form.groupList = this.additionalDl.concat(this.corpoDlList);
      this.form.distributionList = this.form.groupList.map((s) => {
        return s.id;
      });
      this.form.accessFormLogObj = this.accessFormLogObj;
      this.form.currentUserInfo = this.currentUser;
      this.form.startDate = moment(this.form.startDate).format("YYYY-MM-DD");
      this.form.idBuilding = this.form.office.Id;
      this.form.managerId = this.form.manager.id;
      this.form.managerName = this.form.manager.displayName;
      this.form.managerEmail = this.form.manager.mail;
      this.form.licenses = this.getLicensesListObj();
      this.form.job = this.form.job ? this.form.job.jobTitle : "";

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.disabled = false;
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
  },
  components: {
    SendNotificationEmail,
    Datepicker,
    MultiSelectTable,
  },
};
</script>