<template>
  <div>
    <b-row v-if="productsNameList.length > 0">
      <b-col sm="6" class="mt-2">
        <div>{{ $t("action.getLicensesReport") }}</div>
      </b-col>
    </b-row>
    <b-row v-if="productsNameList.length > 0">
      <b-col sm="6" class="mt-2">
        <datepicker
          input-class="datepicker"
          v-model="licenseMonth"
          :placeholder="$t('action.chooseDate')"
          :language="language"
          :minimumView="'month'"
          :maximumView="'month'"
          :disabledDates="disabledMonthLicense"
        ></datepicker>
      </b-col>
    </b-row>

    <b-row v-if="buildingLicensesList.length > 0">
      <b-col sm="6" class="mt-2">
        <ExcelReport
          :tabList="setDataForExcelReport()"
          fileName="LicensesReport.xlsx"
          :sessionLog="sessionLog"
        />
      </b-col>
    </b-row>

    <b-row v-if="productsNameList.length > 0">
      <b-col sm="6" class="mt-2">
        <b-button
          v-if="!generateReportMessage"
          variant="link"
          @click="generateNewReport()"
          >{{ $t("action.generateNewReport") }}</b-button
        >
        <div v-else class="mt-2">
          <b-alert show variant="success">
            <p>{{ generateReportMessage }}</p>
          </b-alert>
        </div>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
    <div v-if="error" class="pt-4">
      <b-alert show variant="danger">
        <p>{{ error }}</p>
        <a href="https://cogirnet-my.sharepoint.com/" target="_blank">{{
          $t("label.webUrl")
        }}</a>
      </b-alert>
    </div>
    <div v-if="success" class="pt-4">
      <b-alert show variant="success">
        <p>{{ $t("info.successExcel") }}</p>
        <a :href="webUrl" target="_blank">{{ $t("label.webUrl") }}</a>
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExcelReport from "./ExcelReportMultipleTab.vue";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";

import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: [],
  data: () => ({
    error: "",
    spinner: false,
    success: false,
    productsNameList: [],
    buildingLicensesList: [],
    licenseMonth: "",
    generateReportMessage: false,
    userLicenses: [],
    departmentRatio: []
  }),
  mounted() {
    this.spinner = true;
    let token = this.$store.getters.getAppAccessToken;
    this.getSubscribedSkus(token);
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    sessionLog: function () {
      return {
        request: "Product Name Report",
        length: this.productsNameList.length,
        search: "",
      };
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    disabledMonthLicense: function () {
      return { from: moment().toDate() };
    },
    otherProducts: function () {
      return this.$store.getters.getOtherProducts;
    },
  },
  watch: {
    licenseMonth: function (newDate) {
      this.error = "";
      this.spinner = false;
      this.success = false;
      this.buildingLicensesList = [];
      this.getUserLicensesByMonth(newDate);
    },
  },
  methods: {
    getUserLicensesByMonth(newDate) {
      let month = moment(newDate).startOf("month").format("YYYY-MM-DD");
      let nextMonth = moment(newDate)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.spinner = true;
      axios
        .post(
          this.url,
          {
            request: "getUserLicensesByMonth",
            month: month,
            nextMonth: nextMonth,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          if (response.data) {
            this.departmentRatio = response.data.departmentRatio;
            let usersLicenses = this.parseStringUsersLicenses(response.data.usersLicenses);
            if (usersLicenses.length > 0) {
              this.userLicenses = usersLicenses;
              let groupByBuilding = this.groupByBuilding(usersLicenses);
              this.buildingLicensesList =
                this.getBuildingLicenses(groupByBuilding);
            }
          }

          this.disabled = false;
          this.spinner = false;
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });
    },
    groupByBuilding(usersLicenses) {
      let buildings = this.$store.getters.getBuildings;
      for (let i = 0; i < usersLicenses.length; i++) {
        let userBuilding = usersLicenses[i].officeLocation;
        if (userBuilding) {
          let findBuilding = buildings.find((b) => {
            let validBuildingName = b.ResName.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/\W/g, "")
              .trim();
            let userBuldingStripped = userBuilding
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/\W/g, "")
              .trim();

            return validBuildingName === userBuldingStripped;
          });

          if (findBuilding) {
            usersLicenses[i].officeLocation = findBuilding.ResName;
            usersLicenses[i].solulan = findBuilding.Solulan;
            usersLicenses[i].class = findBuilding.Class;
          }
        }
      }

      return usersLicenses;
    },
    getBuildingLicenses(usersLicenses) {
      let buildingLicenses = [
        {
          buildingName: "No building us",
          class: "",
          usageLocation: null,
          licensesList: [],
          solulan: 0
        },
        {
          buildingName: "No building ca",
          class: "",
          usageLocation: null,
          licensesList: [],
          solulan: 0
        },
        {
          buildingName: "No building",
          class: "",
          usageLocation: null,
          licensesList: [],
          solulan: 0
        },
      ];

      //let totalCount = 0;
      for (let i = 0; i < usersLicenses.length; i++) {
        let userBuilding = usersLicenses[i].officeLocation;
        let userClass = usersLicenses[i].class;
        let userLocation = usersLicenses[i].usageLocation ? usersLicenses[i].usageLocation : "";
        let userLicenses = usersLicenses[i].assignedLicenses;
        let solulan = usersLicenses[i].solulan;
        let buildingIndex = -1;

        if (userBuilding) {
          buildingIndex = buildingLicenses.findIndex(
            (b) =>
              b.buildingName
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\W/g, "")
                .trim() ===
              userBuilding
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/\W/g, "")
                .trim()
          );
        }

        if (buildingIndex !== -1) {
          let buildingLicensesList =
            buildingLicenses[buildingIndex].licensesList;
          let validLicensesList = this.getValidLicenses(userLicenses); //TODO

          for (let j = 0; j < validLicensesList.length; j++) {
            let buildingLicensesIndex = buildingLicensesList.findIndex(
              (b) => b.skuId === validLicensesList[j].skuId
            );
            if (buildingLicensesIndex !== -1) {
              buildingLicensesList[buildingLicensesIndex].count += 1;
            } else {
              validLicensesList[j].count = 1;
              buildingLicensesList.push(validLicensesList[j]);
            }
          }
          //totalCount += 1;
          buildingLicenses[buildingIndex].licensesList = buildingLicensesList;
          buildingLicenses[buildingIndex].solulan = solulan;
        } else {
          let buildingObj = {
            buildingName: "",
            class: "",
            usageLocation: userLocation,
            licensesList: [],
            solulan: 0
          };

          if (userBuilding && userBuilding.length > 0) {
            buildingObj.buildingName = userBuilding;
            buildingObj.class = userClass;
            let validLicenses = this.getValidLicenses(userLicenses);
            if (validLicenses.length > 0) {
              buildingObj.licensesList = validLicenses.map((v) => {
                v.count = 1;
                return v;
              });
            }
            //totalCount += 1;
            buildingLicenses.push(buildingObj);
          } else {
            let buildingIndex;
            //valider usage location
            if (userLocation.toUpperCase().trim() === "US") {
              buildingIndex = buildingLicenses.findIndex(
                (b) => b.buildingName === "No building us"
              );
            } else if (userLocation.toUpperCase().trim() === "CA") {
              buildingIndex = buildingLicenses.findIndex(
                (b) => b.buildingName === "No building ca"
              );
            } else {
              buildingIndex = buildingLicenses.findIndex(
                (b) => b.buildingName === "No building"
              );
            }

            //faire no building us et no building ca
            let buildingLicensesList =
              buildingLicenses[buildingIndex].licensesList;
            let validLicensesList = this.getValidLicenses(userLicenses); //TODO

            for (let j = 0; j < validLicensesList.length; j++) {
              let buildingLicensesIndex = buildingLicensesList.findIndex(
                (b) => b.skuId === validLicensesList[j].skuId
              );
              if (buildingLicensesIndex !== -1) {
                buildingLicensesList[buildingLicensesIndex].count += 1;
              } else {
                validLicensesList[j].count = 1;
                buildingLicensesList.push(validLicensesList[j]);
              }
            }
            //totalCount += 1;
            buildingLicenses[buildingIndex].usageLocation = userLocation;
            buildingLicenses[buildingIndex].licensesList = buildingLicensesList;
            buildingLicenses[buildingIndex].solulan = solulan;
          }
        }
      }

      let buildingLicensesSort = buildingLicenses.sort(function (a, b) {
        var textA = a.buildingName.toUpperCase().trim();
        var textB = b.buildingName.toUpperCase().trim();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      let buildingLicensesGroupBy =
        this.groupByUsageLocation(buildingLicensesSort);

      let buildingLicensesGroupByFilter = buildingLicensesGroupBy.filter(b => b.licensesList.length > 0);

      return buildingLicensesGroupByFilter;
    },
    groupByUsageLocation(buildingLicensesSort) {
      let can = [];
      let noBuildingCa = [];
      let usa = [];
      let noBuildingUs = [];
      let other = [];

      for (let i = 0; i < buildingLicensesSort.length; i++) {
        let buildingLicense = buildingLicensesSort[i];
        if (buildingLicense.usageLocation) {
          if (
            buildingLicense.usageLocation.toUpperCase().trim() === "US" &&
            buildingLicense.buildingName === "No building us"
          ) {
            noBuildingUs.push(buildingLicense);
          } else if (
            buildingLicense.usageLocation.toUpperCase().trim() === "US" &&
            buildingLicense.buildingName !== "No building us"
          ) {
            usa.push(buildingLicense);
          } else if (
            buildingLicense.usageLocation.toUpperCase().trim() === "CA" &&
            buildingLicense.buildingName === "No building ca"
          ) {
            noBuildingCa.push(buildingLicense);
          } else if (
            buildingLicense.usageLocation.toUpperCase().trim() === "CA" &&
            buildingLicense.buildingName !== "No building ca"
          ) {
            can.push(buildingLicense);
          } else {
            other.push(buildingLicense);
          }
        } else {
          other.push(buildingLicense);
        }
      }

      return can.concat(noBuildingCa, usa, noBuildingUs, other);
    },
    getValidLicenses(userLicenses) {
      let validSubscribedSkus = this.$store.getters.getSubscribedSkus;

      let validUserLicenses = userLicenses.filter((u) => {
        let find = validSubscribedSkus.find(
          (v) => v.SkuId === u.skuId //chercher par id
        );
        if (find) {
          u.productName = find.ProductName;
          u.price = find.Price;
          return u;
        }
      });

      return validUserLicenses;
    },
    parseStringUsersLicenses(userLicensesFromDb) {
      let strList = userLicensesFromDb.JobData;
      let usersLicenses = [];
      if (strList) {
        try {
          usersLicenses = JSON.parse(strList);
        } catch (error) {
          this.error = error;
        }
      }

      return usersLicenses;
    },
    getSubscribedSkus(token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/subscribedSkus`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.spinner = false;
          let productNameList = this.$store.getters.getGraphProductName;
          this.productsNameList = this.mapLicencesWithProductName(
            response.data.value,
            productNameList
          );
        })
        .catch((error) => {
          this.error = error;
          this.spinner = false;
        });
    },
    mapLicencesWithProductName(licenses, productsName) {
      //Ajouter prix en bd ou créer une nouvelle table des licenses valides
      return licenses.map((l) => {
        let pnobject = productsName.find(
          (p) => p.GUID === l.skuId 
        );
        l.displayName = l.skuPartNumber;
        if (pnobject) {
          l.displayName = pnobject.Product_Display_Name;
        }
        return l;
      });
    },
    //Parmi les licenses d'un immeuble, combien ont les deux plans de courriel (servicePlanId) Ensuite, possibilité de multiplier
    getTotalNumberOfMailLicenseByBuilding: function (
      licensesList,
      otherProductPrice
    ) {
      let validSubscribedSkus = this.$store.getters.getSubscribedSkus;
      let total = 0;
      let totalLicenses = 0;
      for (let i = 0; i < licensesList.length; i++) {
        let skuId = licensesList[i].skuId;

        if (skuId) {
          let findEmailLicense = validSubscribedSkus.find(
            (s) =>
              s.SkuId === skuId && s.PaidLicense === 'Y'
          );
          if (findEmailLicense) {
            //Pour obtenir le nombre de license par utilisateur multiplié licensesList[i].count {id,price,productName,servicePlans,skuId,skuPartNumber}
            let productUserCount = parseInt(licensesList[i].count);
            let subTotal = otherProductPrice * productUserCount;
            totalLicenses += productUserCount;
            total += subTotal;
          }
        }
      }
      return { totalPrice: total, totalLicenses: totalLicenses };
    },
    setDataForExcelReport: function () {
      let tab1 = {
        worksheetName: "BuildingLicenses",
        data: this.getBuildingLicenseData(),
        range: this.getBuildingLicenseDataRange(),
      };

      let tab2 = {
        worksheetName: "Facturation",
        data: this.getFacturationData(),
        range: this.getFacturationDataDataRange(),
      };

      let tab3 = {
        worksheetName: "Répartition(corpo)",
        data: this.getRepartitionData(),
        range: this.getRepartitionDataDataRange(),
      };

      return [tab1, tab2, tab3];
    },
    getRepartitionData: function () {
      let all = [];
      let departmentRatio = this.departmentRatio;
      let classInfoCount = this.getUserByBuildingClass();
      let corpo = classInfoCount.find((d) => d.class === 10);

      let repartionByDepartment = departmentRatio.map((d) => {
        d.numberOfLicenseByRatio = Math.round(corpo.licensesCount * (d.DepartmentRatio / 100));
        return d;
      });

      let header = [];
      let space = [];
      let value = [];

      for (let i = 0; i < repartionByDepartment.length; i++) {
        let departmentName = repartionByDepartment[i].DepartmentName;
        let numberOfLicenseByRatio = repartionByDepartment[i].numberOfLicenseByRatio
        header.push(departmentName);
        space.push("");
        value.push(numberOfLicenseByRatio);
      }

      all.push(header);
      all.push(space);
      all.push(value);

      return all;
    },
    getBuildingLicenseData: function () {
      let all = [];
      let data = this.buildingLicensesList;

      let header = [
        "Nom de l'immeuble",
        "Nom du produit",
        "Nombre de licence",
        "Prix",
        "Total",
      ];

      all.push(header);
      all.push(["", "", "", "", ""]);

      for (let i = 0; i < data.length; i++) {
        let buildingLine = [];
        let buildingName = data[i].buildingName ? data[i].buildingName : "";
        let licensesList = data[i].licensesList ? data[i].licensesList : [];
        let usageLocation = data[i].usageLocation ? data[i].usageLocation : "";
        let solulan = data[i].solulan ? data[i].solulan : 0;
        let firstTitle = "";
        if (buildingName && usageLocation) {
          firstTitle = buildingName + " (" + data[i].usageLocation + ")";
        }
        let totalColonne = 0;
        let totalColonneLine = [];

        buildingLine.push(firstTitle, "", "", "", "");

        all.push(buildingLine);

        //other products
        if (licensesList.length > 0) {
          if (this.otherProducts.length > 0) {
            for (let i = 0; i < this.otherProducts.length; i++) {
              let otherProduct = this.otherProducts[i];

              if (solulan === 1 && otherProduct.ProductName === "Labtech") {
                continue;
              } else {
                let otherProductsLicenses = [];
                let price = parseFloat(otherProduct.Price);
                let emailLicenseObj =
                  this.getTotalNumberOfMailLicenseByBuilding(
                    licensesList,
                    price
                  );

                otherProductsLicenses.push(
                  "",
                  otherProduct.En,
                  emailLicenseObj.totalLicenses,
                  price,
                  emailLicenseObj.totalPrice
                );

                totalColonne += emailLicenseObj.totalPrice;

                all.push(otherProductsLicenses);
              }
            }
          }

          //Graph Licenses
          for (let j = 0; j < licensesList.length; j++) {
            let licenseLine = [];
            let count = parseInt(licensesList[j].count);
            let price = parseFloat(licensesList[j].price);
            let total = 0;

            if (Number.isNaN(count) || Number.isNaN(price)) {
              total = 0;
            } else {
              total = count * price;
            }

            licenseLine.push(
              "",
              licensesList[j].productName,
              licensesList[j].count,
              licensesList[j].price,
              total
            );

            totalColonne += total;

            all.push(licenseLine);
          }
        }

        let totalColonneValue = totalColonne > 0 ? totalColonne : "";

        totalColonneLine.push("", "", "", "", totalColonneValue);

        all.push(totalColonneLine);
        all.push(["", "", "", "", ""]);
      }

      all.push([
        "Nom du Produit",
        "Total disponible",
        "Assigné",
        "Restante",
        "SkuId",
      ]);
      all.push(["", "", "", "", ""]);

      let productsNameList = this.productsNameList;

      for (let p = 0; p < productsNameList.length; p++) {
        let productNameLine = [];
        let enabled = parseInt(productsNameList[p].prepaidUnits.enabled);
        let assigned = parseInt(productsNameList[p].consumedUnits);
        let available = "";

        if (Number.isNaN(enabled) || Number.isNaN(assigned)) {
          available = "";
        } else {
          available = enabled - assigned;
        }

        productNameLine.push(
          productsNameList[p].displayName,
          enabled,
          assigned,
          available,
          productsNameList[p].skuId
        );

        all.push(productNameLine);
      }

      //Ticket number and user by building class
      all.push(["", "", "", "", ""]);

      all.push(["Classe", "Nombre de boîte courriel (Paid License)", "", "", ""]);

      all.push(["", "", "", "", ""]);

      let classInfoCount = this.getValidLicenseByBuildingClass();

      for (let i = 0; i < classInfoCount.length; i++) {
        let userClass = [classInfoCount[i].className, classInfoCount[i].count, "", "", ""];
        all.push(userClass);
      }

      return all;
    },
    getValidLicenseByBuildingClass: function () {

      let className = this.$store.getters.getClassNames;
      let data = this.buildingLicensesList;
      let validSubscribedSkus = this.$store.getters.getSubscribedSkus;
      let buildingLicenses = [];

      for (let i = 0; i < validSubscribedSkus.length; i++) {
        let validLicense = validSubscribedSkus[i];
        if (validLicense.PaidLicense === 'Y') {
          for (let j = 0; j < data.length; j++) {
            let licensesList = data[j].licensesList;
            let findLicense = licensesList.find(l => l.skuId === validLicense.SkuId);
            if (findLicense) {
              data[j].validLicense = validLicense;
              buildingLicenses.push(data[j]);
            }
          }
        }
      }

      let classInfoCount = className.map(c => {
        return {
          class: c.Id,
          className: c.Fr,
          count: 0,
          licensesCount: 0
        }
      });

      for (let i = 0; i < buildingLicenses.length; i++) {
        let buildingLicense = buildingLicenses[i];
        let index = classInfoCount.findIndex(c => c.class === buildingLicense.class);
        if (index !== -1) {
          classInfoCount[index].count += 1;
        }
      }

      return classInfoCount;
    },
    getUserByBuildingClass: function () {
      let buildings = this.$store.getters.getBuildings;
      let className = this.$store.getters.getClassNames;
      
      let usersLicenses = this.userLicenses;
      
      for (let i = 0; i < usersLicenses.length; i++) {
        let user = usersLicenses[i];
        let userBuilding = null;
        let officeLocation = user.officeLocation ? user.officeLocation : "";
        let licensesCount = user.assignedLicenses ? user.assignedLicenses.length : 0;
        if (user) {
          userBuilding = buildings.find(
            (b) =>
              b.ResName.trim().toLowerCase() ==
              officeLocation.trim().toLowerCase()
          );
          usersLicenses[i].building = userBuilding ? userBuilding : "No building found";
          usersLicenses[i].class = userBuilding ? userBuilding.Class : "No Class found";
          usersLicenses[i].licensesCount = licensesCount;
        }
      }

      let classInfoCount = className.map(c => {
        return {
          class: c.Id,
          className: c.Fr,
          count: 0,
          licensesCount: 0
        }
      });

      for (let i = 0; i < usersLicenses.length; i++) {
        let user = usersLicenses[i];
        let index = classInfoCount.findIndex(c => c.class === user.class);
        if (index !== -1) {
          classInfoCount[index].count += 1;
          classInfoCount[index].licensesCount += user.licensesCount;
        }
      }

      return classInfoCount;
    },
    getBuildingLicenseDataRange: function () {
      let excelData = this.getBuildingLicenseData();
      //De A à Z correspond au nombre de champs dans l'entête (nom, courriel, consent, etc.).
      return "A1:E" + excelData.length;
    },
    getFacturationData: function () {
      let all = [];
      let data = this.buildingLicensesList;
      let buildings = this.$store.getters.getBuildings;
      let header = [
        "Code de facturation",
        "Nom de l'immeuble",
        "Description",
        "Total",
      ];

      all.push(header);
      all.push(["", "", "", ""]);

      for (let i = 0; i < data.length; i++) {
        let buildingName = data[i].buildingName ? data[i].buildingName : "";
        let solulan = data[i].solulan ? data[i].solulan : 0;
        let totalColonne = 0;
        let building = buildings.find((b) => {
          let validBuildingName = b.ResName.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\W/g, "")
            .trim();
          let userBuldingStripped = buildingName
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\W/g, "")
            .trim();

          return validBuildingName === userBuldingStripped;
        });

        let codeFacturation = building ? building.CodeFacturation : "";
        let description = "Licenses Mens. O365 Anti-virus";

        //Total - mettre dans une fonction
        let licensesList = data[i].licensesList ? data[i].licensesList : [];

        //other products
        if (licensesList.length > 0) {
          if (this.otherProducts.length > 0) {
            for (let i = 0; i < this.otherProducts.length; i++) {
              let otherProduct = this.otherProducts[i];

              if (solulan === 1 && otherProduct.ProductName === "Labtech") {
                continue;
              } else {
                let price = parseFloat(otherProduct.Price);
                let emailLicenseObj =
                  this.getTotalNumberOfMailLicenseByBuilding(
                    licensesList,
                    price
                  );

                totalColonne += emailLicenseObj.totalPrice;
              }
            }
          }

          //Graph Licenses
          for (let j = 0; j < licensesList.length; j++) {
            //let licenseLine = [];
            let count = parseInt(licensesList[j].count);
            let price = parseFloat(licensesList[j].price);
            let total = 0;

            if (Number.isNaN(count) || Number.isNaN(price)) {
              total = 0;
            } else {
              total = count * price;
            }

            totalColonne += total;
          }
        }

        let totalColonneValue = totalColonne > 0 ? totalColonne : "";

        all.push([
          codeFacturation,
          buildingName,
          description,
          totalColonneValue,
        ]);
      }

      return all;
    },
    getRepartitionDataDataRange: function () {
      let excelData = this.getRepartitionData();
      let headerLength = excelData[0].length;
      let alphabetics = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let range = "A1:" + alphabetics[headerLength - 1] + excelData.length;
      //De A à Z correspond au nombre de champs dans l'entête (nom, courriel, consent, etc.).
      return range;
    },
    getFacturationDataDataRange: function () {
      let excelData = this.getFacturationData();
      //De A à Z correspond au nombre de champs dans l'entête (nom, courriel, consent, etc.).
      return "A1:D" + excelData.length;
    },
    generateNewReport: function () {
      //this.disabled = true;
      this.spinner = true;
      let startTime = moment()
        .subtract(10, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      let endTime = moment().format("YYYY-MM-DD HH:mm:ss");

      let recipientName = this.user.displayName;

      axios
        .post(
          this.url,
          {
            request: "getAllGraphUserWithLicense",
            type: "licenseReport",
            recipientEmail: this.user.mail,
            recipientName: recipientName,
            startTime: startTime,
            endTime: endTime,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          if (response.data) {
            this.departmentRatio = response.data.departmentRatio;
            let usersLicenses = response.data.usersLicenses;
            if (usersLicenses.length > 0) {
              this.userLicenses = usersLicenses;
              let groupByBuilding = this.groupByBuilding(usersLicenses);
              this.buildingLicensesList =
                this.getBuildingLicenses(groupByBuilding);
            }
          }

          this.disabled = false;
          this.spinner = false;
          
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });
    }
  },
  components: {
    ExcelReport,
    Datepicker,
  },
};
</script>