<template>
  <b-container v-if="getAppRole(user.AppRole, 1) && isTenantAsAccess">
    <b-form @submit="onSubmit">
      <b-row>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.fname") }}</strong>
          </div>
          <b-form-input
            v-model.trim="form.fname"
            :placeholder="$t('label.fname')"
          ></b-form-input>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.lname") }}</strong>
          </div>
          <b-form-input
            v-model.trim="form.lname"
            :placeholder="$t('label.lname')"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.email") }}</strong>
          </div>
          <b-form-input
            v-model.trim="form.email"
            :placeholder="$t('label.email')"
            readonly
          ></b-form-input>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.mobile") }}</strong>
          </div>
          <b-form-input
            v-model.trim="form.tel"
            placeholder="format: 1111111111"
            readonly
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="4">
          <div class="mt-4">
            <strong>{{ $t("label.officePhone") }}</strong>
          </div>
          <b-form-input
            v-model="form.businessPhones"
            placeholder="format: 1111111111"
            readonly
          ></b-form-input>
        </b-col>
        <b-col sm="2">
          <div class="mt-4">
            <strong>{{ $t("label.ext") }}</strong>
          </div>
          <b-form-input
            v-model="form.ext"
            placeholder="format: 11111"
            readonly
          ></b-form-input>
        </b-col>
        <b-col sm="6">
          <div class="mt-4">
            <strong>{{ $t("label.communication") }}</strong>
          </div>
          <b-form-select
            v-model.trim="form.communication"
            :options="communication"
          ></b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="bypassOffice" sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.offices") }}</strong>
          </div>
          <b-form-input v-model="form.office" readonly></b-form-input>
        </b-col>

        <b-col v-else sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.offices") }}</strong>
          </div>
          <v-select
            v-model.trim="form.office"
            :options="buildings"
            :selectable="(option) => !option.Flag"
            :filter="searchBuilding"
            label="ResName"
            :placeholder="$t('action.chooseBuilding')"
            :value="form.office" 
            @input="setJobTitleList"
          >
            <template slot="option" slot-scope="option">
              <strong
                style="color: black"
                v-if="option.Flag === 'ClassName'"
                disabled
                >{{ option.ResName }}</strong
              >
              <span v-else>{{ option.ResName }}</span>
            </template>
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.searchBuilding") }}</small>
          </div>
        </b-col>
        <b-col sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.job") }}</strong>
          </div>
          <v-select
            v-model.trim="form.job"
            :options="jobTitleList"
            :selectable="(option) => !option.Flag"
            :filter="searchJobTitleList"
            label="jobTitle"
            :placeholder="$t('label.job')"
          >
            <template slot="option" slot-scope="option">
              <strong
                style="color: black"
                v-if="option.Flag === 'ClassName'"
                disabled
                >{{ option.jobTitle }}</strong
              >
              <span v-else>{{ option.jobTitle }}</span>
            </template>
          </v-select>
          <div>
            <small>&#9432; {{ $t("info.jobTitleList") }}</small>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="bypassManager === 'needValidation'" sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.manager") }}</strong> :
            {{ displayManagerName() }}
          </div>
          <v-select
            v-model.trim="form.manager"
            :options="users"
            @search="searchUser"
            :placeholder="$t('action.chooseManager')"
            label="displayName"
          >
          </v-select>
        </b-col>
        <b-col v-else sm="6" class="mt-4">
          <div>
            <strong>{{ $t("label.manager") }}</strong> :
            {{ displayManagerName() }}
          </div>
          <v-select
            v-model.trim="form.manager"
            :options="users"
            @search="searchUser"
            :placeholder="$t('action.chooseManager')"
            label="displayName"
          >
          </v-select>
        </b-col>
      </b-row>
      <b-row v-if="user.directReports.length > 0">
        <b-col>
          <b-form-checkbox
            id="bypassManager"
            v-model="bypassManager"
            name="checkboxBypassManager"
            value="bypass"
            unchecked-value="needValidation"
          >
            {{ $t("info.bypassManager") }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-button
        class="mt-4"
        type="submit"
        variant="success"
        :disabled="disabled"
        >{{ $t("action.submitUserForm") }}</b-button
      >
      <b-row v-if="success" class="text-center pt-4">
        <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
            <p>{{ $t("info.delayedUpdate") }}</p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="error" class="text-center pt-4">
        <b-col>
          <b-alert show variant="danger">
            <p>{{ error }}</p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="info" class="text-center pt-4">
        <b-col>
          <b-alert show variant="info">
            <p>{{ info }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
  <b-container v-else>
    <div v-if="userInfo.displayName">
      {{ $t("label.displayName") }} : {{ userInfo.displayName }}
    </div>
    <hr />
    <div v-if="userInfo.mail">
      {{ $t("label.email") }} :
      <a :href="'mailto:' + userInfo.mail">{{ userInfo.mail }}</a>
    </div>
    <div v-if="userInfo.businessPhones">
      <div v-if="userInfo.businessPhones.length > 0">
        {{ $t("label.officePhone") }} :
        <div
          v-for="(phone, index) in userInfo.businessPhones"
          :key="'busphone' + (index + 1)"
        >
          {{ phone }}
        </div>
      </div>
    </div>
    <div v-if="userInfo.mobilePhone">
      {{ $t("label.mobile") }} : {{ userInfo.mobilePhone }}
    </div>
    <hr />
  </b-container>
</template>

<script>
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ["userInfo", "currentUser", "tenant", "tenantListToken"],
  data() {
    return {
      form: {
        id: this.getData("id"),
        fname: this.getData("givenName"),
        lname: this.getData("surname"),
        email: this.getData("mail"),
        tel: this.getPhoneNumber("mobilePhone"),
        job: this.getJobTitle(),
        office: this.getOfficeData(),
        businessPhones: this.getPhoneNumber("businessPhones"),
        communication: this.getData("preferredLanguage"),
        ext: this.getExt(),
        manager: "",
        IdManager: "",
      },
      allDomains: [],
      managers: [],
      directReports: this.setUserDirectReports(),
      spinner: false,
      success: false,
      disabled: false,
      users: [],
      buildings: [],
      jobTitleList: [],
      error: "",
      info: "",
      bypassManager: "needValidation",
    };
  },
  mounted() {
    this.spinner = true;
    if (this.getAppRole(this.user.AppRole, 1) && this.isTenantAsAccess) {
      let promise = this.validateToken();
      promise.then((value) => {
        if (value) {
          this.getAllDomains(value);
        }
      });
      let token = this.$store.getters.getAppAccessToken;
      let idUser = this.userInfo.id;
      if (token) {
        let request = [this.getManager(token, idUser)];
        Promise.allSettled(request).then((results) => {
          let manager = results[0];

          if (manager.value) {
            if (manager.value.status === 200) {
              this.form.manager = manager.value.data;
              if (this.form.manager) {
                this.form.IdManager = manager.value.data.id;
              }
            }
          }
          let office = this.getOfficeData();
          this.setJobTitleList(office);
          this.getBuildingList();
        });
      } else {
        this.error = this.$t("info.errorgeneric");
      }
    }
  },
  computed: {
    isTenantAsAccess: function () {
      if (this.tenant) {
        let tenantList = this.$store.getters.getTenantList;
        let selectTenant = tenantList.find(t => t.value === this.tenant)
        let tenantAccess = this.$store.getters.getTenantAppAccess;
        let userInfoModal = tenantAccess.userInfoModal;
        return userInfoModal.includes(selectTenant.id);
      }
      else {
        return false;
      }
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    url: function () {
      return this.getUrl();
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    bypassOffice: function () {
      return this.$store.getters.getEmailNoBuildingSent;
    },
    communication: function () {
      return [
        { value: null, text: this.$t("label.language") },
        { value: "fr-FR", text: this.$t("label.french") },
        { value: "en-US", text: this.$t("label.english") },
      ];
    },
  },
  watch: {
    currentLg: function () {
      this.actionCall("selectList");
    },
  },
  methods: {
    setJobTitleList: function (value) {
      if (value) {
        this.getJobTitleList(value.Class);
      }
      else {
        this.jobTitleList = [];
      }
    },
    getJobTitle: function () {
      let lg = this.$i18n.locale;
      let jobTitle = this.getData("jobTitle");
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleMap = jobTitleList.map(l => {
          l.jobTitle = lg === "fr" ? l.Fr : l.En;
          return l;
        })
      let jobTitleObj = jobTitleMap.find((b) => b.Fr === jobTitle);
      if (!jobTitleObj) {
        jobTitleObj = jobTitleMap.find((b) => b.En === jobTitle);
      }
      
      return jobTitleObj ? jobTitleObj : null;
    },
    getOfficeData: function () {
      let officeName = this.getData("officeLocation");
      let buildings = this.$store.getters.getBuildings;
      let building = buildings.find((b) => b.ResName === officeName);
      return building ? building : null;
    },
    getData: function (type) {
      let user = this.userInfo;
      let propertyValue = user[type];
      if (type === "businessPhones" && Array.isArray(propertyValue)) {
        propertyValue = propertyValue.length > 0 ? propertyValue[0] : "";
      }
      return propertyValue;
    },
    setUserDirectReports: function () {
      let user = this.$store.getters.getUser;
      return this.filterDirectReports(user.directReports);
    },
    filterDirectReports: function (directReports) {
      return directReports.filter((dr) => dr.displayName.indexOf("*") === -1);
    },
    getPhoneNumber: function (type) {
      let propertyValue = this.getData(type);
      let formatPhone = null;

      if (type === "businessPhones" && Array.isArray(propertyValue)) {
        propertyValue = propertyValue.length > 0 ? propertyValue[0] : "";
      }

      if (propertyValue) {
        let splitPhone = propertyValue.split("x"); //get the string before and after the extension
        if (splitPhone.length === 2) {
          formatPhone = splitPhone[0].replace(/\D/g, "");
        } else {
          formatPhone = propertyValue.replace(/\D/g, "");
        }

        formatPhone = formatPhone.slice(1); //remove 1 in front of the phone number
      }

      return formatPhone;
    },
    getExt: function () {
      let ext = null;
      let propertyValue = this.getData("businessPhones");

      if (propertyValue) {
        let splitPhone = propertyValue.split("x");

        if (splitPhone.length === 2) {
          ext = splitPhone[1];
        }
      }

      return ext;
    },
    searchJobTitleList(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let JobTitlePar = o.Fr ? o.Fr.replace(/[()]/g, "") : ""; //Remove ()
        let JobTitle = JobTitlePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        return (
          JobTitle.toLowerCase().includes(s) 
        );
      });

      return find;
    },
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return (
          City.toLowerCase().includes(s) ||
          ClassNamesFr.toLowerCase().includes(s) ||
          ClassNamesEn.toLowerCase().includes(s) ||
          ResName.toLowerCase().includes(s) ||
          Country.toLowerCase().includes(s) ||
          State.toLowerCase().includes(s) ||
          Street.toLowerCase().includes(s) ||
          Zip.toLowerCase().includes(s)
        );
      });

      return find;
    },
    searchUser: function (searchText) {
      this.actionCall("allUsers", searchText);
    },
    actionCall: function (type, searchText) {
      this.info = "";
      this.error = "";
      this.success = false;
      let promise = this.validateToken();
      promise.then((value) => {
        if (value) {
          if (type === "allUsers") {
            this.searchUsersBySearchText(value, searchText);
          }
          if (type === "selectList") {
            this.getBuildingList();
            let office = this.form.office;
            this.setJobTitleList(office);
          }
        }
      });
    },
    getUserDirectReports(token, idUser) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}?$expand=directReports`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          this.spinner = false;
          if (response.status === 200) {
            if (Array.isArray(response.data.directReports)) {
              this.directReports = this.filterDirectReports(
                response.data.directReports
              );
            }
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getAllUsers(token, searchText) {
      if (searchText) {
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchText}')&$orderby=displayName&$count=true&$top=10`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.spinner = false;
            if (response.status === 200) {
              this.users = response.data.value.filter(
                (a) =>
                  a.displayName.indexOf("*") === -1 &&
                  this.validateTenantDomain(a.mail, this.allDomains)
              );
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getManager(token, idUser) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/manager`;
      return axios.get(graphEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ConsistencyLevel: "eventual",
        },
      });
    },
    getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapSelectList(buildings, "building");
    },
    getJobTitleList: function (classId) {
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleFilter = jobTitleList.filter(j => j.Class === classId);
      this.mapSelectList(jobTitleFilter, "jobTitle");
    },
    getManagerRoot: function (obj) {
      for (let property in obj) {
        if (property === "manager") {
          this.managers.unshift(obj.manager);
          if (obj.manager.manager) {
            this.getManagerRoot(obj.manager);
          }
        }
      }
    },
    mapSelectList: function (list, type) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < list.length; i++) {
        let find = uniqueClass.find((c) => c === list[i].Class);
        if (!find) {
          uniqueClass.push(list[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let ListByClassWithLabel = [];

      if (type === 'building') {
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = list.filter((b) => b.Class === uniqueClass[i]);
          let className =
            lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          blist.unshift({ ResName: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }
          this.buildings = ListByClassWithLabel.flat();
      }

      if (type === 'jobTitle') {
        let jobTitleMap = list.map(l => {
          l.jobTitle = lg === "fr" ? l.Fr : l.En;
          return l;
        })
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = jobTitleMap.filter((b) => b.Class === uniqueClass[i]);
          let className = lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          //let displayName = lg === "fr" ? blist[0].Fr : blist[0].En;
          blist.unshift({ jobTitle: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }

        this.jobTitleList = ListByClassWithLabel.flat();
      }
    },
    mapBuilding: function (buildings) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < buildings.length; i++) {
        let find = uniqueClass.find((c) => c === buildings[i].Class);
        if (!find) {
          uniqueClass.push(buildings[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let buildingsListByClassWithLabel = [];

      for (let i = 0; i < uniqueClass.length; i++) {
        let blist = buildings.filter((b) => b.Class === uniqueClass[i]);
        let className =
          lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
        blist.unshift({ ResName: className, Flag: "ClassName" });
        buildingsListByClassWithLabel.push(blist);
      }

      this.buildings = buildingsListByClassWithLabel.flat();
    },
    displayManagerName: function () {
      let manager = this.form.manager;
      let name = "";
      if (manager) {
        name = manager.displayName ? manager.displayName : "";
      }
      return name;
    },
    getPhoneFormat: function (type) {
      let formatPhone = " "; //format for graph if we want to set an empty value
      let phone = this.form[type];
      if (phone) {
        let part0 = "+1";
        let part1 = phone.slice(0, 3);
        let part2 = phone.slice(3, 6);
        let part3 = phone.slice(6);

        formatPhone = part0.concat(" ", part1, "-", part2, "-", part3);

        if (type === "businessPhones" && this.form.ext) {
          formatPhone = formatPhone.concat("x", this.form.ext);
        }
      }

      return formatPhone;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.success = false;
      this.error = "";
      this.info = "";
      let promise = this.validateToken();
      promise.then(() => {
        let request = [];
        let appToken = this.tenantListToken; //this.$store.getters.getAppAccessToken;
        let user = this.userInfo;
        let userData = this.validateUserDataChange();

        if (Object.keys(userData).length > 0) {
          request.push(this.updateUser(appToken, userData, user));
        }

        if (this.form.manager) {
          request.push(this.updateManager(appToken, user));
        }
        
        if (request.length > 0) {
          this.spinner = true;
          Promise.allSettled(request).then((results) => {
            this.spinner = false;
            if (results.length > 0) {
              //log info
              let obj = {
                Email: this.user.mail,
                LogOut: null,
                Request: null,
                Submit: JSON.stringify(results),
              };
              this.updateSession(obj);
              for (let i = 0; i < results.length; i++) {
                if (results[i].status !== "fulfilled") {
                  this.error = this.$t("info.errorgeneric");
                } else {
                  this.success = true;
                  let res = results[i].value;
                  if (res.status !== 204) {
                    this.success = false;
                    this.error = this.$t("info.errorgeneric");
                  }
                }
              }
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          });
        }
      });
    },
    updateUser(token, obj, user) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.id}`;
      return axios.patch(graphEndpoint, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    },
    updateManager(token, user) {
      if (this.form.manager) {
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.id}/manager/$ref`;
        return axios.put(
          graphEndpoint,
          {
            "@odata.id": `https://graph.microsoft.com/v1.0/users/${this.form.manager.id}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      }
    },
    updatePhone(token, obj, user) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.id}`;
      return axios.patch(graphEndpoint, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    },
    validateUserDataChange() {
      let user = {};

      if (this.form.fname) {
        user.givenName = this.form.fname;
      }

      if (this.form.lname) {
        user.surname = this.form.lname;
      }

      if (this.form.job) {
        let lg = this.$i18n.locale; 
        user.jobTitle = lg === 'fr' ? this.form.job.Fr : this.form.job.En;
      }

      if (this.form.communication) {
        user.preferredLanguage = this.form.communication;
      }

      if (this.form.office) {
        user.officeLocation = this.form.office.ResName;
        user.country = this.form.office.Country;
        user.usageLocation = this.form.office.CountryCode;
        user.state = this.form.office.State;
        user.city = this.form.office.City;
        user.streetAddress = this.form.office.Street;
        user.postalCode = this.form.office.Zip;
      }

      return user;
    }
  },
  components: {},
};
</script>
