<template>
  <b-container>
  <b-form @submit="onSubmit">

  <b-alert show variant="danger">
    <p>{{ $t("info.delete") }}</p>
    <p v-if="type === 'City'"><strong>{{this.values.Name}}</strong></p>
    <p v-else-if="type === 'PropertyName'"><strong>{{this.values.ResName}}</strong></p>
    <p v-else-if="type === 'Campaign'"><strong>{{this.values.Name}}</strong></p>
    <p v-else-if="type === 'PayrollCompany'"><strong>{{this.values.name}}</strong></p>
    <p v-else><strong>{{this.values.Fr}} - {{this.values.En}}</strong></p>
  </b-alert>
  
  <b-button class="mt-4 text-center" type="submit" variant="danger" :disabled="disabled">{{ $t("action.submit") }}</b-button>

    <div v-if="error" class="pt-4">
      <b-alert show variant="info">
        <p>{{ error }}</p>
      </b-alert>
    </div>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner style="width: 3rem; height: 3rem;" variant="success" label="Spinning" disabled="labelStatus"></b-spinner>
    </div>

  </b-form>
</b-container>
</template>

<script>

import axios from 'axios';
import { globalF } from '../Helpers';

export default {
mixins: [globalF],
props: ['values', 'type', 'modalid'],
data() {
  return {
    form: {},
    spinner: false,
    disabled: false,
    error: ""
}},
computed: {
  url: function () {
    return this.getUrl();
  }
 },
methods:{
    onSubmit(evt) {
      evt.preventDefault();

        this.spinner = true;
        this.form.request = "deleteAdminTableValues";
        this.form.Type = this.type;
        this.form.Id = this.values.Id;

        axios.post(this.url, this.form, { headers: {'Content-Type': 'application/json'}})
         .then((response)  => {
           this.disabled = false;
           this.spinner = false;
           
           if (response.data === 0) {
             this.$bvModal.hide(this.modalid);
           }
           else {
             this.error = this.$t('info.errorDelete');
           }
         })
         .catch(()  => {
           this.error = this.$t('info.errorgeneric');
           this.disabled = false;
           this.spinner = false;
         });
  }
}
}
</script>
