<template>
  <b-container>
    <b-nav tabs class="mt-4">
      <b-nav-item :active="active === 'res'" @click="active = 'res'">{{
        $t("label.resName")
        }}</b-nav-item>
      <b-nav-item v-if="getAppRole(user.AppRole, 5)" :active="active === 'JobTitle'" @click="active = 'JobTitle'">{{
        $t("label.jobTitle") }}</b-nav-item>
      <b-nav-item v-if="getAppRole(user.AppRole, 5)" :active="active === 'TerminationReason'"
        @click="active = 'TerminationReason'">{{ $t("label.terminationReason") }}</b-nav-item>
    </b-nav>

    <AdminTable v-if="active === 'res'" :list="[]" type="PropertyName" client="Rh" />
    <AdminTable v-if="active === 'JobTitle'" :list="[]" type="JobTitle" client="Ti" />
    <AdminTable v-if="active === 'TerminationReason'" :list="[]" type="TerminationReason" client="Ti" />

  </b-container>
</template>

<script>
import { globalF } from "./Helpers";
import AdminTable from "./AdminTable";

export default {
  mixins: [globalF],
  data() {
    return {
      active: 'res',
    };
  },
  validations() { },
  mounted() { },
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    setNextPage(page) {
      this.$emit("changePage", page);
    }
  },
  components: {
    AdminTable
  },
};
</script>
