<template>
  <b-container>
    <b-row class="mt-4">
      <b-col sm="6">
        <b-alert show variant="info">
          <p>{{ $t("info.warningAccessForm") }}</p>
        </b-alert>
      </b-col>
    </b-row>
    <div class="mt-4">
      <!-- Access request / Access modification radio button -->
      <h5 class="mt-4">{{ $t("label.accessFormTitle") }}</h5>

      <b-row class="pt-2" v-if="multiTenantSelect">
        <b-col sm="6" class="mt-2">
          <div>
            <strong>Microsoft Tenant</strong>
          </div>
          <b-form-select v-model="tenant" :options="tenantList" v-on:change="OnTenantClick"
            class="mt-2"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="pt-2" v-else>
        <b-col sm="6" class="mt-2">
          <div>
            <strong>Microsoft Tenant : </strong>
            {{ tenant }}
          </div>
        </b-col>
      </b-row>

      <div v-if="buildings.length > 0" class="mt-4">
        <b-form-group>
          <b-form-radio v-model="requestType" name="requestType" value="new">{{ $t("label.newAccess") }}
            <span v-b-tooltip.hover :title="$t('tooltip.newAccess')">&#9432;</span></b-form-radio>
          <b-form-radio v-model="requestType" name="requestType" value="modification">{{ $t("label.accesModification")
            }}
            <span v-b-tooltip.hover :title="$t('tooltip.accesModification')">&#9432;</span>
          </b-form-radio>
        </b-form-group>

        <b-row v-if="requestType === 'modification'">
          <b-col sm="6" class="mt-4">
            <v-select v-model="existingUser" :options="users" @search="searchUser" :placeholder="searchByMail === 'yes'
              ? $t('action.chooseUserByMail')
              : $t('action.chooseUser')
              " :label="searchByMail === 'yes' ? 'mail' : 'displayName'">
            </v-select>
            <small>&#9432; {{ $t("info.searchByName") }}</small>
          </b-col>
        </b-row>

        <b-row v-if="requestType === 'modification'">
          <b-col sm="6" class="mt-1">
            <b-form-checkbox class="mt-1" v-model="searchByMail" name="searchByMail" value="yes" unchecked-value="no">{{
              $t("label.searchByMail") }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr />

        <!-- Acces request / Access modification forms -->
        <b-form v-if="requestType === 'new' || requestType === 'modification'" @submit="onSubmit">
          <b-row>
            <b-col sm class="mt-4">
              <div>
                <strong>{{ $t("label.fname") }}</strong>
              </div>
              <b-form-input v-model.trim="$v.form.fname.$model" :placeholder="$t('label.fname')"></b-form-input>
              <div class="text-danger" v-if="!$v.form.fname.required && $v.form.fname.$dirty">
                {{ $t("info.required") }}
              </div>
              <div class="text-danger" v-if="!$v.form.fname.maxLength && $v.form.fname.$dirty">
                {{ $t("info.maxLength", [75]) }}
              </div>
            </b-col>
            <b-col sm class="mt-4">
              <div>
                <strong>{{ $t("label.lname") }}</strong>
              </div>
              <b-form-input v-model.trim="$v.form.lname.$model" :placeholder="$t('label.lname')"></b-form-input>
              <div class="text-danger" v-if="!$v.form.lname.required && $v.form.lname.$dirty">
                {{ $t("info.required") }}
              </div>
              <div class="text-danger" v-if="!$v.form.lname.maxLength && $v.form.lname.$dirty">
                {{ $t("info.maxLength", [75]) }}
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6" class="mt-4">
              <div>
                <strong>{{ $t("label.offices") }}</strong>
              </div>
              <v-select v-model.trim="$v.form.office.$model" :options="buildings" :selectable="(option) => !option.Flag"
                :filter="searchBuilding" label="ResName" :placeholder="$t('action.chooseBuilding')" :value="form.office"
                @input="setJobTitleList">
                <template slot="option" slot-scope="option">
                  <strong style="color: black" v-if="option.Flag === 'ClassName'" disabled>{{ option.ResName }}</strong>
                  <span v-else>{{ option.ResName }}</span>
                </template>
              </v-select>
              <div>
                <small>&#9432; {{ $t("info.searchBuilding") }}</small>
              </div>
              <div class="text-danger" v-if="!$v.form.office.required && $v.form.office.$dirty">
                {{ $t("info.required") }}
              </div>
              <b-button variant="link" @click="openmodal('sendNotificationEmail', null)">{{
                $t("label.cannotFindMyBuilding")
                }}</b-button>
            </b-col>
            <b-col sm="6" class="mt-4">
              <div>
                <strong>{{ $t("label.job") }}</strong>
              </div>
              <v-select v-model.trim="$v.form.job.$model" :options="jobTitleList" :selectable="(option) => !option.Flag"
                :filter="searchJobTitleList" label="jobTitle" :placeholder="$t('label.job')">
                <template slot="option" slot-scope="option">
                  <strong style="color: black" v-if="option.Flag === 'ClassName'" disabled>{{ option.jobTitle
                    }}</strong>
                  <span v-else>{{ option.jobTitle }}</span>
                </template>
              </v-select>
              <div>
                <small>&#9432; {{ $t("info.jobTitleList") }}</small>
              </div>
              <div class="text-danger" v-if="!$v.form.job.required && $v.form.job.$dirty">
                {{ $t("info.required") }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" class="mt-4">
              <div>
                <strong>{{ $t("label.manager") }}</strong> :
                {{ displayManagerName() }}
              </div>

              <v-select v-model.trim="$v.form.manager.$model" :options="users" @search="searchUser" :placeholder="searchManagerByMail === 'yes'
                ? $t('action.chooseManagerByMail')
                : $t('action.chooseManager')
                " :label="searchManagerByMail === 'yes' ? 'mail' : 'displayName'">
              </v-select>
              <small>&#9432; {{ $t("info.searchByName") }}</small>
              <b-row>
                <b-col sm="6" class="mt-1">
                  <b-form-checkbox class="mt-1" v-model="searchManagerByMail" name="searchManagerByMail" value="yes"
                    unchecked-value="no">{{ $t("label.searchByMail") }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <div class="text-danger" v-if="!$v.form.manager.required && $v.form.manager.$dirty">
                {{ $t("info.required") }}
              </div>
            </b-col>
            <b-col sm="6">
              <div class="mt-4">
                <strong>{{ $t("label.communication") }}</strong>
              </div>
              <b-form-select v-model.trim="$v.form.communication.$model" :options="communication"></b-form-select>
              <div class="text-danger" v-if="!$v.form.communication.required && $v.form.communication.$dirty
              ">
                {{ $t("info.required") }}
              </div>
            </b-col>
          </b-row>

          <b-row v-if="requestType === 'new'" class="mt-2">
            <hr />
            <b-col sm>
              <div>
                <strong>{{ $t("label.campaignStartDate") }}</strong>
              </div>
              <datepicker input-class="datepicker" v-model="form.startDate" :placeholder="$t('action.chooseDate')"
                :language="language"></datepicker>
              <div class="text-danger" v-if="!$v.form.startDate.required && $v.form.startDate.$dirty">
                {{ $t("info.required") }}
              </div>
              <div class="mt-2 text-danger">
                {{ $t("info.businessAdvances") }}
              </div>
            </b-col>
          </b-row>
          <hr />
          <div>
            <b-row>
              <b-col class="mt-2">
                <div class="mt-2">
                  <b-row class="mt-2">
                    <b-col sm="6">
                      <div v-if="requestType === 'new'">
                        <strong>{{ $t("label.email") }} </strong> :
                        {{ $t("info.futureEmail") }} :
                        <span>{{
                          newEmailUser.length > 1
                            ? newEmailUser + "@" + form.domain
                            : ""
                          }}</span>
                      </div>
                      <div v-if="requestType === 'modification'">
                        <strong>{{ $t("label.email") }} </strong> :
                        {{ form.email ? form.email : "no email" }}
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-form-checkbox id="customEmailFlag" v-model="form.customEmailFlag" class="mt-2" name="customEmailFlag"
                  value="yes" unchecked-value="no">
                  {{ $t("label.customEmail") }}
                </b-form-checkbox>
                <div class="mt-2" v-if="form.customEmailFlag === 'yes'">
                  <b-row class="mt-2">
                    <b-col sm="6">
                      <div>
                        <strong>{{ $t("label.nameForEmail") }}</strong>
                      </div>
                      <b-input-group :append="'@' + form.domain">
                        <b-form-input v-model="$v.form.nameForEmail.$model" class="text-right"></b-form-input>
                      </b-input-group>
                      <div class="text-danger" v-if="!$v.form.nameForEmail.required &&
                        $v.form.nameForEmail.$dirty
                      ">
                        {{ $t("info.required") }}
                      </div>
                      <div class="text-danger" v-if="!$v.form.nameForEmail.maxLength &&
                        $v.form.nameForEmail.$dirty
                      ">
                        {{ $t("info.maxLength", [75]) }}
                      </div>
                    </b-col>
                    <b-col sm="6">
                      <div>
                        <strong>{{ $t("label.domains") }}</strong>
                      </div>
                      <b-form-select v-model="form.domain" :options="domains"></b-form-select>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <hr />
          </div>

          <div>
            <strong>{{ $t("label.accessType") }}</strong>
          </div>

          <!-- Acces request / Access modification Checkbox, text box, comments -->
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="softwareAccess" v-model="accessRequestList" :aria-describedby="ariaDescribedby"
              name="remote" stacked>
              <b-form-checkbox v-if="form.office && form.office.Class === 10" value="vpn">VPN</b-form-checkbox>
              <b-form-checkbox v-if="form.office && form.office.Class === 10" value="accesscardmtl">{{
                $t("label.mtlAccessCard") }}</b-form-checkbox>
              <b-form-checkbox v-if="form.office && form.office.Class === 10" value="accesscardbr">{{
                $t("label.brAccessCard") }}</b-form-checkbox>
              <b-form-checkbox value="remote">{{ $t("label.remoteAccess") }}
                <span v-b-tooltip.hover :title="$t('tooltip.portailRemoteApp')">&#9432;</span></b-form-checkbox>
              <b-form-checkbox value="agile">Agile</b-form-checkbox>
              <b-row v-if="showInput('agile')">
                <b-col sm="6" class="m-2">
                  <hr />
                  <!-- Department -->
                  <strong>{{ $t("label.department") }} :</strong>
                  <b-form-input v-model.trim="$v.form.department.$model"
                    :placeholder="$t('action.department')"></b-form-input>
                  <div class="text-danger" v-if="!$v.form.department.required && $v.form.department.$dirty
                  ">
                    {{ $t("info.required") }}
                  </div>
                  <div class="text-danger" v-if="!$v.form.department.maxLength && $v.form.department.$dirty
                  ">
                    {{ $t("info.maxLength", [75]) }}
                  </div>
                  <!-- Salary view -->
                  <strong>{{ $t("label.salaryView") }} :</strong>
                  <b-form-group>
                    <b-form-radio v-model="form.salaryView" name="salaryView" value="yes">{{ $t("label.yes")
                      }}</b-form-radio>
                    <b-form-radio v-model="form.salaryView" name="salaryView" value="no">{{ $t("label.no")
                      }}</b-form-radio>
                  </b-form-group>
                  <!-- Send Access To -->
                  <strong>{{ $t("label.sendAccessTo") }} :</strong>
                  <b-form-input v-model.trim="$v.form.sendAccessTo.$model"
                    :placeholder="$t('action.sendAccessTo')"></b-form-input>
                  <div class="text-danger" v-if="!$v.form.sendAccessTo.required &&
                    $v.form.sendAccessTo.$dirty
                  ">
                    {{ $t("info.required") }}
                  </div>
                  <div class="text-danger" v-if="!$v.form.sendAccessTo.maxLength &&
                    $v.form.sendAccessTo.$dirty
                  ">
                    {{ $t("info.maxLength", [75]) }}
                  </div>
                  <hr />
                </b-col>
              </b-row>

              <b-form-checkbox v-for="cs in collaboratorService" :key="cs.id" :value="cs.Service">{{ currentLg === "en"
                ?
                cs.En : cs.Fr }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <hr>

          <!-- Other software -->
          <b-row>
            <b-col sm="6" class="mt-2">
              <div>{{ $t("label.otherSoftware") }} :</div>
              <b-form-input v-model.trim="form.otherSoftware" :placeholder="$t('action.otherSoftware')"></b-form-input>
            </b-col>
          </b-row>

          <hr />

          <b-row class="mt-2">
            <b-col sm="6">
              <div>
                <strong>{{ $t("label.comments") }} </strong><span v-b-tooltip.hover
                  :title="$t('tooltip.accessFormComments')">&#9432;</span>
              </div>
              <b-form-checkbox class="mt-1" v-model="form.materialNeeds" name="materialNeeds" value="yes"
                unchecked-value="no">{{
                  $t("label.materialNeeds") }}
              </b-form-checkbox>
              <b-form-textarea class="mt-1" id="comments" v-model="$v.form.comments.$model" :placeholder="form.materialNeeds === 'yes'
                ? $t('label.materialNeedsInfo')
                : ''
                " rows="5" max-rows="10"></b-form-textarea>
              <div class="text-danger" v-if="!$v.form.comments.maxLength && $v.form.comments.$dirty">
                {{ $t("info.maxLength", [1000]) }}
              </div>
            </b-col>
          </b-row>

          <!-- Acces request / Access modification Add to distribution list -->
          <b-row class="mt-2" v-if="requestType === 'new'">
            <b-col>
              <div class="mt-2">
                <b-alert show variant="info">
                  <div>
                    <strong>{{ $t("info.dlForAll") }}</strong>
                  </div>

                  <div class="mt-2" v-if="corpoDlList.length > 0">
                    <ul>
                      <li v-for="item in corpoDlList" :key="item.id">
                        {{ item.displayName }}
                      </li>
                    </ul>
                  </div>

                  <div class="mt-2" v-if="additionalDl.length > 0">
                    <ul>
                      <li v-for="item in additionalDl" :key="item.id">
                        {{ item.displayName }}
                      </li>
                    </ul>
                    <b-button variant="link" @click="resetAdditionnalDlList()">{{
                      $t("action.resetTheList")
                      }}</b-button>
                  </div>

                  <div class="mt-4" v-if="distListFromGraph.length > 0">
                    <b-button variant="outline-info" @click="openmodal('multiSelectTable')">{{ $t("info.addToDl")
                      }}</b-button>
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-row>

          <b-button v-if="distListFromGraph.length > 0" class="mt-4" type="submit" variant="success"
            :disabled="disabled">{{
              $t("action.submit") }}</b-button>

          <b-modal id="multiSelectTable" size="xl" :title="$t('label.distributionList')" hide-footer>
            <MultiSelectTable ref="multiSelectDl" :tableDataFromProp="distListFromGraph"
              :fields="fieldsForMultipleSelectTable" tableName="distributionList" :corpoDlList="corpoDlList" />
          </b-modal>

          <b-modal id="sendNotificationEmail" :title="$t('label.emailNotification')" hide-footer>
            <SendNotificationEmail modalid="sendNotificationEmail" />
          </b-modal>
        </b-form>
      </div>

      <div v-if="spinner" class="text-center align-middle">
        <b-spinner style="width: 3rem; height: 3rem" variant="success" label="Spinning"
          disabled="labelStatus"></b-spinner>
      </div>

      <b-row v-if="success" class="text-center pt-4">
        <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="existingEmail" class="pt-4">
        <b-col>
          <b-alert show variant="danger">
            <strong>{{ $t("info.existingEmail") }}</strong>
            <ul>
              <li>{{ existingEmail.displayName }}</li>
              <li>{{ existingEmail.mail }}</li>
            </ul>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="error" class="mt-2">
        <b-col sm class="mt-4">
          <b-alert show variant="danger">
            <p>{{ error }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { required, maxLength } from "vuelidate/lib/validators";
import SendNotificationEmail from "../Modal/SendNotificationEmail";
import MultiSelectTable from "./MultipleSelectTable.vue";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      tenant: this.getCurrentTenant(),
      token: "",
      searchByMail: "no",
      searchManagerByMail: "no",
      allDomains: [],
      domains: [],
      accessRequestList: [],
      distListFromGraph: [],
      rawData: [],
      form: {
        id: null,
        fname: "",
        lname: "",
        email: null,
        job: "",
        office: null,
        manager: "",
        communication: null,
        startDate: "",
        customEmail: "",
        customEmailFlag: "no",
        nameForEmail: "",
        agileRequest: false,
        department: "",
        salaryView: "no",
        sendAccessTo: "",
        otherSoftware: "",
        materialNeeds: "no",
        comments: "",
        domain: "",
      },
      requestType: "",
      managers: [],
      spinner: false,
      success: false,
      disabled: false,
      users: [],
      existingUser: null,
      buildings: [],
      jobTitleList: [],
      error: "",
      info: "",
      addToDl: "no",
      existingEmail: null,
    };
  },
  validations() {
    let form = {
      fname: {
        required,
        maxLength: maxLength(75),
      },
      lname: {
        required,
        maxLength: maxLength(75),
      },
      job: {
        required
      },
      manager: { required },
      communication: { required },
      office: { required },
      comments: { maxLength: maxLength(1000) },
    };

    if (this.requestType === "new") {
      form.startDate = { required };
    }

    if (this.form.customEmailFlag === "yes") {
      form.nameForEmail = { required, maxLength: maxLength(75) };
    }

    if (this.showInput("agile")) {
      form.department = { required, maxLength: maxLength(75) };
      form.sendAccessTo = { required, maxLength: maxLength(75) };
    }

    return { form };
  },
  mounted() {
    if (this.tenant) {
      this.spinner = true;
      this.getAppTokenForTenant();
    }
  },
  computed: {
    multiTenantSelect() {
      let currentAccount = this.$store.getters.getMicrosoftAccount;
      let tenantAccess = this.$store.getters.getTenantAppAccess;
      let multiTenantSelectList = tenantAccess.multiTenantSelect;
      return multiTenantSelectList.includes(currentAccount.tenantId);
    },
    tenantList: function () {
      let tenantList = this.$store.getters.getTenantList;
      let tenantAccess = this.$store.getters.getTenantAppAccess;
      let accessFormList = tenantAccess.accessForm;

      let filterList = tenantList.filter(t => accessFormList.includes(t.id));

      return filterList.map(t => {
        return {
          "value": t.value,
          "text": t.name
        }
      });
    },
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    collaboratorService: function () {
      let cs = this.$store.getters.getCollaboratorService;
      let filterCs = cs.filter(
        (c) =>
          //c.Service !== "vpn" && c.Service !== "remote" && c.Service !== "agile" && c.Service !== "accesscardmtl" && c.Service !== "accesscardbr"
          c.ConditionalDisplay == "0"
      );
      filterCs.sort((a, b) => {
        if (a.Service < b.Service) {
          return -1;
        }
        if (a.Service > b.Service) {
          return 1;
        }
        return 0;
      });
      return filterCs;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    additionalDl: function () {
      return this.$store.getters.getAdditionnalDlList;
    },
    corpoDlList: function () {
      let corpoDlList = [];

      if (this.form.office) {
        let officeClass = this.form.office.Class;
        let officeId = this.form.office.Id;
        //à mettre en bd
        if (officeClass === 10 && officeId === 302) {
          corpoDlList = this.getCorpoList("dist_Cogir-Brossard");
        }
        if (officeClass === 10 && officeId === 303) {
          corpoDlList = this.getCorpoList("dist_Cogir-Quebec");
        }
        if (officeClass === 10 && officeId === 304) {
          corpoDlList = this.getCorpoList("dist_Cogir-Montréal");
        }
        if (officeClass === 10 && officeId === 305) {
          corpoDlList = this.getCorpoList("dist_Cogir-Whitby");
        }
      }

      return corpoDlList;
    },
    communication: function () {
      return [
        { value: null, text: this.$t("label.language") },
        { value: "fr-FR", text: this.$t("label.french") },
        { value: "en-US", text: this.$t("label.english") },
      ];
    },
    fieldsForMultipleSelectTable: function () {
      return [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        { key: "mail", sortable: true, label: this.$t("label.email") },
        {
          key: "createdDateTime",
          sortable: true,
          label: this.$t("label.creation"),
        },
        { key: "Details", label: this.$t("label.details") },
      ];
    },
    newEmailUser: function () {
      let firstName = this.form.fname.trim();
      let lastName = this.form.lname.trim();
      let firstLetterName = firstName.charAt(0);

      let emailToSuggest = firstLetterName + lastName;

      let defaultEmail = emailToSuggest
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z]+/g, "")
        .toLowerCase();

      return defaultEmail;
    },
  },
  watch: {
    currentLg: function () {
      this.actionCall("building");
    },
    existingUser: function (user) {
      if (user) {
        this.form.id = user.id;
        this.form.fname = user.givenName;
        this.form.lname = user.surname;
        this.form.email = user.mail;
        this.form.job = this.getJobTitle(user.jobTitle);
        this.form.communication = user.preferredLanguage;
        this.form.office = this.getOfficeData(user.officeLocation);
        this.form.manager = user.manager;
      } else {
        this.form.id = null;
        this.form.fname = "";
        this.form.lname = "";
        this.form.email = null;
        this.form.job = "";
        this.form.communication = null;
        this.form.office = null;
        this.form.manager = "";
      }
    },
    requestType: function (/*value*/) {
      this.disabled = false;
      this.success = false;
      this.error = "";
      this.existingEmail = null;

      this.form.id = null;
      this.form.fname = "";
      this.form.lname = "";
      this.form.email = null;
      this.form.job = "";
      this.form.communication = null;
      this.form.office = null;

      this.form.manager = "";
      this.form.startDate = "";
      this.form.customEmailFlag = "no";
      this.form.nameForEmail = "";
      this.form.customEmail = "";
      this.form.customEmailFlag = "no";
      this.form.agileRequest = false;
      this.form.department = "";
      this.form.salaryView = "no";
      this.form.sendAccessTo = "";
      this.form.otherSoftware = "";
      this.form.comments = "";
      this.form.materialNeeds = "no";

      this.accessRequestList = [];
    },
  },
  methods: {
    setJobTitleList: function (value) {
      this.form.job = null;
      if (value) {
        this.getJobTitleList(value.Class);
      }
      else {
        this.jobTitleList = [];
      }
    },
    OnTenantClick() {
      if (this.tenant) {
        this.spinner = true;
        this.buildings = [];
        this.rawData = [];
        this.distListFromGraph = [];
        this.$store.commit("addAdditionnalDlList", []);
        this.token = "";
        this.error = "";
        this.existingUser = "";
        this.users = [];
        this.form.manager = "";
        this.getAppTokenForTenant();
      }
    },
    getAppTokenForTenant() {
      if (this.tenant) {
        let tenantList = this.$store.getters.getTenantList;
        let tenantId = tenantList.find(t => t.value === this.tenant).id;
        this.form.domain = tenantList.find(t => t.value === this.tenant).name;

        let obj = {
          request: "getAppTokenByTenant",
          tenantId: tenantId,
        };
        axios
          .post(this.url, obj, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            let accessTokenObj = JSON.parse(response.data.accessToken);
            this.token = accessTokenObj.access_token;
            this.getDomains(accessTokenObj.access_token);
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getCurrentTenant() {
      let tenantList = this.$store.getters.getTenantList;
      let currentAccount = this.$store.getters.getMicrosoftAccount;
      let defaultTenant = this.$store.getters.getDefaultTenant;
      let tenantAccess = this.$store.getters.getTenantAppAccess;
      let accessForm = tenantAccess.accessForm;

      if (accessForm.includes(currentAccount.tenantId)) {
        return tenantList.find(t => t.id === currentAccount.tenantId).value;
      }
      else {
        return tenantList.find(t => t.id === defaultTenant).value;
      }
    },
    getDomains(token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/domains`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let allDomains = response.data.value;
          this.allDomains = response.data.value;
          if (allDomains) {
            this.domains = allDomains.map((d) => {
              return {
                value: d.id,
                text: d.id,
              };
            });
          }
          this.$store.commit("addAdditionnalDlList", []);
          let graphEndpoint = "https://graph.microsoft.com/v1.0/groups";
          this.getGroups(token, graphEndpoint);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getCorpoList: function (corpoName) {
      return this.distListFromGraph.filter(
        (dl) => dl.displayName === corpoName
      );
    },
    resetAdditionnalDlList: function () {
      this.$store.commit("addAdditionnalDlList", []);
    },
    getGroups: function (token, graphEndpoint) {
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getGroups(token, nextLink);
            } else {
              this.spinner = false;
              let flatList = this.rawData.flat();
              this.distListFromGraph = flatList;
              this.getBuildingList();
              this.setJobTitleList();
            }
          } else {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    showInput: function (value) {
      return this.accessRequestList.includes(value);
    },
    getOfficeData: function (officeName) {
      let building = null;
      if (officeName) {
        let buildings = this.$store.getters.getBuildings;
        building = buildings.find((b) => b.ResName === officeName);
      }

      if (building) {
        this.setJobTitleList(building);
      }

      return building ? building : null;
    },
    getJobTitle: function (jobTitle) {
      let lg = this.$i18n.locale;
      //let jobTitle = this.getData("jobTitle");
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleMap = jobTitleList.map(l => {
        l.jobTitle = lg === "fr" ? l.Fr : l.En;
        return l;
      })
      let jobTitleObj = jobTitleMap.find((b) => b.Fr === jobTitle);
      if (!jobTitleObj) {
        jobTitleObj = jobTitleMap.find((b) => b.En === jobTitle);
      }

      return jobTitleObj ? jobTitleObj : null;
    },
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return (
          City.toLowerCase().includes(s) ||
          ClassNamesFr.toLowerCase().includes(s) ||
          ClassNamesEn.toLowerCase().includes(s) ||
          ResName.toLowerCase().includes(s) ||
          Country.toLowerCase().includes(s) ||
          State.toLowerCase().includes(s) ||
          Street.toLowerCase().includes(s) ||
          Zip.toLowerCase().includes(s)
        );
      });

      return find;
    },
    searchJobTitleList(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let JobTitlePar = o.Fr ? o.Fr.replace(/[()]/g, "") : ""; //Remove ()
        let JobTitle = JobTitlePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        return (
          JobTitle.toLowerCase().includes(s)
        );
      });

      return find;
    },
    searchUser: function (search) {
      this.actionCall("allUsers", search);
    },
    actionCall: function (type, searchText) {
      this.info = "";
      this.error = "";
      this.success = false;

      if (type === "allUsers") {
        this.searchUsersBySearchText(this.token, searchText);
      }
      if (type === "building") {
        this.getBuildingList();
      }
    },
    getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapSelectList(buildings, "building");
    },
    getJobTitleList: function (classId) {
      let jobTitleList = this.$store.getters.getJobTitleList;
      let jobTitleFilter = jobTitleList.filter(j => j.Class === classId);
      this.mapSelectList(jobTitleFilter, "jobTitle");
    },
    mapSelectList: function (list, type) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < list.length; i++) {
        let find = uniqueClass.find((c) => c === list[i].Class);
        if (!find) {
          uniqueClass.push(list[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let ListByClassWithLabel = [];

      if (type === 'building') {
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = list.filter((b) => b.Class === uniqueClass[i]);
          let className =
            lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          blist.unshift({ ResName: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }
        this.buildings = ListByClassWithLabel.flat();
      }

      if (type === 'jobTitle') {
        let jobTitleMap = list.map(l => {
          l.jobTitle = lg === "fr" ? l.Fr : l.En;
          return l;
        })
        for (let i = 0; i < uniqueClass.length; i++) {
          let blist = jobTitleMap.filter((b) => b.Class === uniqueClass[i]);
          let className = lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
          blist.unshift({ jobTitle: className, Flag: "ClassName" });
          ListByClassWithLabel.push(blist);
        }

        this.jobTitleList = ListByClassWithLabel.flat();
      }
    },
    displayManagerName: function () {
      let manager = this.form.manager;
      let name = "";
      if (manager) {
        name = manager.displayName ? manager.displayName : "";
      }
      return name;
    },
    openmodal: function (id) {
      this.$bvModal.show(id);
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    setNextStep(step) {
      this.$emit("changeStep", step);
    },
    getEmailForUser: function () {
      let emailToValidate = null;
      if (this.form.customEmailFlag === "yes") {
        emailToValidate = this.form.nameForEmail + "@" + this.form.domain;
      } else {
        let firstName = this.form.fname.trim();
        let lastName = this.form.lname.trim();
        let firstLetterName = firstName.charAt(0);

        let emailToSuggest = firstLetterName + lastName;

        let emailFirstPart = emailToSuggest
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z]+/g, "")
          .toLowerCase();

        emailToValidate = emailFirstPart + "@" + this.form.domain;
      }
      return emailToValidate;
    },
    getUser(token) {
      let emailToValidate = this.getEmailForUser();
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${emailToValidate}`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          this.existingEmail = response.data;
          this.disabled = false;
          this.spinner = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              this.setAccessForm();
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        });
    },
    setAccessForm: function () {
      this.form.requesterId = this.user.id;
      this.form.requesterName = this.user.displayName;
      this.form.requesterEmail = this.user.mail;
      this.form.idBuilding = this.form.office.Id;
      this.form.managerId = this.form.manager.id;
      this.form.managerName = this.form.manager.displayName;
      this.form.managerEmail = this.form.manager.mail;
      this.form.startDate = this.form.startDate
        ? moment(this.form.startDate).format("YYYY-MM-DD")
        : "";
      this.form.accessRequest = this.accessRequestList;
      this.form.agileRequest = this.showInput("agile");

      if (this.form.customEmailFlag === "yes") {
        this.form.customEmail = this.form.nameForEmail + "@" + this.form.domain;
      } else {
        let firstName = this.form.fname.trim();
        let lastName = this.form.lname.trim();
        let firstLetterName = firstName.charAt(0);

        let emailToSuggest = firstLetterName + lastName;

        this.form.nameForEmail = emailToSuggest
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z]+/g, "")
          .toLowerCase();
      }

      this.form.request = "setAccessFormRequest";
      this.form.requestType = this.requestType;
      this.form.subject =
        this.requestType === "new" ? "Access request" : "Access Modification";

      this.form.distributionList = this.additionalDl.map((s) => {
        return s.id;
      });

      if (this.existingUser) {
        this.form.existingUser = this.existingUser;
      }

      let tenantList = this.$store.getters.getTenantList;

      this.form.tenantName = tenantList.find(t => t.value === this.tenant).name;
      this.form.tenantId = tenantList.find(t => t.value === this.tenant).id;

      this.form.job = this.form.job ? this.form.job.jobTitle : "";

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          this.spinner = false;
          if (response.data === 0) {
            this.$emit("changePage", "SuccessConfirmation");
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.error = "";
      this.existingEmail = null;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.disabled = true;
        this.spinner = true;
        if (this.requestType === "new") {
          let token = this.$store.getters.getAppAccessToken;
          this.getUser(token);
        }
        if (this.requestType === "modification") {
          this.setAccessForm();
        }
      }
    },
  },
  components: {
    SendNotificationEmail,
    Datepicker,
    MultiSelectTable,
  },
};
</script>